import React, { useState } from "react";
import { Loading } from "../../components/loading/Loading";
import { useSelector } from "react-redux";
import { GridContainer } from "../../components/grid/GridContainer";

import { CoinsAllHead } from "./coinsAllHead";
import { CoinsAllBody } from "./coinsAllBody";


export function CoinsAll() {
  const idos = useSelector((state) => state.idos.idos);
  const [site, setSite] = useState(idos.length > 0 ? idos[0].name :"ADAPAD");
  const [searchText, setSearchText] = useState("");


  return (
    <GridContainer spacing={2}>
      {idos.length > 0   ? <React.Fragment><CoinsAllHead idos={idos} setSite={setSite} site={site} setSearchText={setSearchText} />
      <CoinsAllBody idos={idos} site={site} searchText={searchText}></CoinsAllBody></React.Fragment> : <Loading></Loading> }
      
    </GridContainer>
  );
}
