
import { configureStore } from "@reduxjs/toolkit";

import webSitesReducer from "../slices/app/websites";
import idosReducer from "../slices/app/idos";
import uiUtilsReducer from "../slices/ui/uitls";



export const store = configureStore({
    reducer: {
      webSites: webSitesReducer,
      idos: idosReducer,
      ui: uiUtilsReducer
    },
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        serializableCheck: false,
      });
    },
  });