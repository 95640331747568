import React from "react";
import { HomePage } from "../pages/home/homePage";
import TokenRoundedIcon from '@mui/icons-material/TokenRounded';
import { Coin } from "../pages/ido/coin";
//import { NotFound } from "../pages/notFound";
import { Site } from "../pages/site/site";
import { Coins } from "../pages/ido/coins";
import WebRoundedIcon from '@mui/icons-material/WebRounded';
import CottageRoundedIcon from '@mui/icons-material/CottageRounded';
import ManageSearchRoundedIcon from '@mui/icons-material/ManageSearchRounded';

import { TrackerPage } from "../pages/app/trackerPage";
import { Sites } from "../pages/site/sites";

export const MENU_LIST = [
  { name: "Home", path: "/" ,icon:<CottageRoundedIcon/> , explain:"Go to Main Page" },
  { name: "Tracker", path: "/app",icon:<ManageSearchRoundedIcon/> , explain:"Own IDOs on App"},
  { name: "WebSites", path: "/websites" ,icon:<WebRoundedIcon/> ,explain: "See All Sites"},
  { name: "IDOs", path: "/idos",icon:<TokenRoundedIcon/> , explain:"See All IDOs" },
];

export const routes = [
  {
    path: "/",
    component:<HomePage /> ,
  },
  {
    path: "/app",
    component: <TrackerPage />,
  },
  {
    path: "/websites",
    component: <Sites />,
  },
  {
    path: "/websites/:siteName",
    component: <Site />,
  },
  {
    path: "/idos",
    component: <Coins />,
  },
  {
    path: "/idos/:name/:address",
    component: <Coin />,
  },
  {
    path: "*",
    component: <HomePage />,
  } /* And so on. */,
];
