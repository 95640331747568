import { useTheme } from "@emotion/react";
import { Grid, useMediaQuery } from "@mui/material";
import React from "react";

export function GridContainer({spacing=1,  children ,rowSpacing=1 }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Grid container  spacing={isSmallScreen ? 0 : spacing}  pl={isSmallScreen?"0px":"16px"} rowSpacing={rowSpacing}>
      {children}
    </Grid>
  );
}
