import React, { useState } from "react";
import { useSelector } from "react-redux";
import { GridContainer } from "../../components/grid/GridContainer";
import { SitesAllHead } from "./sitesAllHead";
import { SitesAllBody } from "./sitesAllBody";



export function SitesAll() {

  const sites = useSelector((state)=> state.webSites.webSites);
  const idos = useSelector((state)=> state.idos.idos);
  const [site, setSite] = useState("");
  const [searchText, setSearchText] = useState("");


  return (
    <GridContainer spacing={2}>
      <SitesAllHead  setSite={setSite} site={site} setSearchText={setSearchText} sites={sites}/>
      <SitesAllBody  siteName={site} sites={sites} searchText={searchText} idos={idos}></SitesAllBody>

      
    </GridContainer>
  );
}
