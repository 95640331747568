import makeStyles from "@mui/styles/makeStyles";

export const useStylesStickColumnTable = makeStyles((theme) => ({
  sticky: {
    position: "sticky",
    left: 0,
    boxShadow: "5px 2px 5px grey",
    backgroundColor:   theme.palette.subColor.light ,
    color: theme.palette.background.paper,
    overflow:"hidden",

    zIndex: theme.zIndex.appBar - 1,
  },
  font: {
    fontSize: 12,
  },
  overflow: {

    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
}));

export const useStylesFont = makeStyles((theme) => ({
  tableFont: {
    fontSize: 12,
  },
}));


export const useStylesOverflowDot = makeStyles((theme) => ({
  overflow: {

    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
}));


export const useStylesSvgIcon = makeStyles({
  imageIcon: {
    height: '100%',
    width:'100%'
  },
  iconRoot: {
    textAlign: 'center',
    fontSize:"1.2rem"
  }
});

export const useStylesPosition = makeStyles((theme) => ({
  sticky: {
    position: "sticky",
    zIndex: theme.zIndex.appBar - 2,
  },
  fixed: {
    position: "fixed",
    zIndex: theme.zIndex.appBar - 2,
  },
  fixedRight: {
    position: "fixed",
    right:theme.spacing(4) , 
    zIndex: theme.zIndex.appBar - 2,
  },
  fixedLeft: {
    position: "fixed",
    left:theme.spacing(4) , 
    zIndex: theme.zIndex.appBar - 2,
  },
  
}));
