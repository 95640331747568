import { useEffect } from "react"
import IdoTrackerService from "../api/service";
import { useDispatch } from "react-redux";
import { setWebSites } from "../stateManagement/slices/app/websites";
import { setGlobalLoading } from "../stateManagement/slices/ui/uitls";

export const useSites = () => {

    const dispatch = useDispatch();
   
    useEffect(() => {
        const getSites = async () => {
          dispatch(setGlobalLoading({value:true , message:"Loading Sites..."}));
          IdoTrackerService.getSites()
            .then((sites) => {
              dispatch(setWebSites({webSites: sites.Items}) ) ;  
                    
            })
            .catch((err) => {
              throw err ;
            });
        };
    
        getSites();
      }, []);
}