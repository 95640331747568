import React from "react";

import { useWeb3React } from "@web3-react/core";

import { Button, Icon, Tooltip, useMediaQuery } from "@mui/material";

import { useTheme } from "@emotion/react";
import { injected } from "./InjectedConnector";
import { AddressCopy } from "./AddressCopy";
import { useStylesSvgIcon } from "../css/Utility";
import { getScanImage, getScanLink, getScanTitle } from "../../../api/constant";
import LogoutIcon from "@mui/icons-material/Logout";
import { GridItem } from "../grid/GridItem";
import { getChainString } from "../../../utils/solidtyInterect";



export function MetamaskConnect() {
  const { active, account, activate, deactivate ,chainId } = useWeb3React();
  const svg = useStylesSvgIcon();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  async function connect() {
    try {
      await activate(injected);
    } catch (ex) {
      console.log(ex);
    }
  }
  async function disconnect() {
    try {
      deactivate();
    } catch (ex) {
      console.log(ex);
    }
  }

  
  return (
    <GridItem padding={isSmallScreen ? "0px":"10px"}>
      {active ? (
        <React.Fragment>
       
          <AddressCopy address={account}>
            <Tooltip title={getScanTitle(chainId)} className={svg.iconRoot}>
              <Icon className={svg.iconRoot}>
                <img
                  alt="Scan"
                  onClick={() =>
                    window.open(
                      getScanLink( getChainString[chainId] ).scan["address"] + account,
                      "_blank"
                    )
                  }
                  className={svg.imageIcon}
                  src={`${process.env.PUBLIC_URL}/${getScanImage(getChainString[chainId])}`}
                />
              </Icon>
            </Tooltip>
            <Tooltip title="Disconnect">
              <LogoutIcon onClick={disconnect}></LogoutIcon>
            </Tooltip>
          </AddressCopy>
        </React.Fragment>
      ) : (
        <Button
          variant="contained"
          endIcon={ <Icon className={svg.iconRoot}  >
          <img
            alt="Metamask"
            
            className={svg.imageIcon}
            src={`${process.env.PUBLIC_URL}/img/metamask.svg`}
          />
        </Icon>}
          sx={{ backgroundColor: theme.palette.subColor.light }}
          onClick={connect}
        >
          Connect
        </Button>
      )}
    </GridItem>
  );
}
