import { useDispatch, useSelector } from 'react-redux'
import { setClipboard } from '../../stateManagement/slices/ui/uitls';

function useCopyClipboard(){
    const dispatch = useDispatch();
    
    const copyText = useSelector((state)=> state.ui.clipboard);
  
    const copy = async text => {
      if (!navigator.clipboard) {
        console.warn('Clipboard not supported')
        return false
      }
  
      // Try to save to clipboard then save it in the state if worked
      try {
        await navigator.clipboard.writeText(text)
        dispatch(setClipboard(text))
        return true
      } catch (error) {
        alert("Copy failed:" + error ) ;
        dispatch(setClipboard(""))
        return false
      }
    }
  
    return [copyText, copy]
  }
  
  export default useCopyClipboard