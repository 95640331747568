
import React from "react";
import {  TableCell } from "@mui/material";

import { Price } from "../blockchain/Price";





export function TableCellPrice({ tokenAddress,network="BNB"  , ...props }) {
  return (
    <TableCell
      key={props.key+"price"}
      align="left" 
      {...props}
    >
      <Price tokenAddress={tokenAddress} tokenNetwork={network} >

      </Price>
    </TableCell>
  );
}
