import React, { useState } from "react";
import { Menu, MenuItem,  Tooltip } from "@mui/material";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export function OpenerListMenu({ list, ...props }) {
  const [anchorEl, setAnchorEl] = useState();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
       <Tooltip title="More">
            <ArrowDropDownIcon
              onClick={handleClick}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            ></ArrowDropDownIcon>
          </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {list.map((element) => {
          return <MenuItem >{element}</MenuItem>;
        })}
      </Menu>
    </React.Fragment>
  );
}
