import React from 'react';
import { Helmet } from 'react-helmet-async';
import { SitesAll } from '../../panels/site/sitesAll';
import { Stack } from '@mui/material';



export function Sites() {

    return    (
      <React.Fragment>
        <Helmet>
        <title>IDO SITES</title>
        <meta name='description' content='BSCPAD, ETHPAD, POLYPAD, METAVPAD and All BLuezilla web sites' />
      </Helmet>
      <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={0.5}
          px={1}
        >
          <SitesAll></SitesAll>
         
        </Stack>
      </React.Fragment>
        
      );


}

