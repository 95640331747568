import React from "react";
import { Card, Grid } from "@mui/material";

import { getCoinBrainTradeWidgetUrl } from "../../../utils/solidtyInterect";
import { useTheme } from "@emotion/react";

export function CoinSelectedGraph({ ido, selectedPad }) {
  const theme = useTheme();

  const getCoinBrainUrl = () => {
    return getCoinBrainTradeWidgetUrl(
      ido.sites[selectedPad].network,
      ido.sites[selectedPad].tokenAddress,
      theme.palette.mode
    );
  };

  return (
    <Grid item xs={12}>
      <Card>
        <iframe title={ido+"-"+selectedPad}
          allowFullScreen
          frameBorder="0"
          width="100%"
          height="600px"
          src={getCoinBrainUrl()}
        ></iframe>
      </Card>
    </Grid>
  );
}
