



export const pancakeSwapAbi = [
  {
    inputs: [
      { internalType: "uint256", name: "amountIn", type: "uint256" },
      { internalType: "address[]", name: "path", type: "address[]" },
    ],
    name: "getAmountsOut",
    outputs: [
      { internalType: "uint256[]", name: "amounts", type: "uint256[]" },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export const tokenAbi = [
  {
    inputs: [],
    name: "decimals",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },

  {
    inputs: [],
    name: "symbol",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { "internalType": "address", "name": "account", "type": "address" }
    ],
    name: "balanceOf",
    outputs: [{ "internalType": "uint256", "name": "", "type": "uint256" }],
    stateMutability: "view",
    type: "function"
  },
];

export const idoContract = [
  {
    inputs: [],
    name: "info",
    outputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "uint", name: "", type: "uint" },
      { internalType: "uint", name: "", type: "uint" },
      { internalType: "uint", name: "", type: "uint" },
      { internalType: "uint", name: "", type: "uint" },
      { internalType: "uint", name: "", type: "uint" },
      { internalType: "uint", name: "", type: "uint" },
      { internalType: "uint", name: "", type: "uint" },
      { internalType: "uint", name: "", type: "uint" },
      { internalType: "uint", name: "", type: "uint" },
      { internalType: "uint", name: "", type: "uint" },
      { internalType: "uint", name: "", type: "uint" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "infoAllocations",
    outputs: [
      { internalType: "uint[]", name: "", type: "uint[]" },
      { internalType: "uint[]", name: "", type: "uint[]" },
      { internalType: "uint[]", name: "", type: "uint[]" },
      { internalType: "uint[]", name: "", type: "uint[]" },
      { internalType: "uint[]", name: "", type: "uint[]" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "claim",
    outputs: [{ internalType: "uint", name: "", type: "uint" }],
    type: "function",
  },
];

export const factoryAbi = [
  {
    inputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "address", name: "", type: "address" },
    ],
    name: "getPair",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "Swap",
    outputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "uint", name: "", type: "uint" },
      { internalType: "uint", name: "", type: "uint" },
      { internalType: "uint", name: "", type: "uint" },
      { internalType: "uint", name: "", type: "uint" },
      { internalType: "address", name: "", type: "address" },
    ],
    payable: false,
    stateMutability: "view",
    type: "event",
  },

  {
    inputs: [],
    name: "Transfer",
    outputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "address", name: "", type: "address" },
      { internalType: "uint", name: "", type: "uint" },
    ],
    payable: false,
    stateMutability: "view",
    type: "event",
  },
];



