import React  from "react";
import { Box, Stack, Tab, Tabs } from "@mui/material";

import { useTheme } from "@emotion/react";
import { Link } from "react-router-dom";

import { BoxBordered } from "../box/BoxBordered";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}



export function TabPanelExt({
  borderBottom = 1,
  borderColor = "divider",
  tabs,
  moreLink,
  selectedPad,
  setSelectedPad,
  tabPanels,
  ...props
}) {
  const theme = useTheme();
 
  const handleChange = (event, newValue) => {
    setSelectedPad(newValue);
  };
  return (
    <BoxBordered
      borderBottom={borderBottom}
      borderColor={borderColor}
      border={0}
    >
      <Box sx={{ borderBottom: borderBottom, borderColor: borderColor }}>
        <Tabs
          value={selectedPad}
          onChange={handleChange}
          aria-label="scrollable force tabs example"
          variant={"scrollable"}
          scrollButtons={true}
          allowScrollButtonsMobile={true}
        >
          {tabs.map((tab, index) => {

            return index<10 ?  <Tab 
              {...a11yProps(index)}
              component={() => (
                <Box
                  
                  p={1}
                  sx={{
                    borderTop: 1,
                    borderLeft: 1,
                    borderRight: 1,
                    borderColor: "divider",
                    backgroundColor:
                      index === selectedPad ? theme.palette.action.selected : "",
                    minWidth: 80,
                    minHeight: 80,
                    width: 80,
                    height: 80,
                  }}
                >
                  <Stack
                    
                    onClick={() => setSelectedPad(index)}
                    {...a11yProps(index)}
                    direction="column"
                    justifyContent="space-around"
                    spacing={1}
                    alignItems="center"
                  >
                    <Box
                       fontSize={10}
                      onClick={() => setSelectedPad(index)}
                    >
                      {tab.name}
                    </Box>
                  
                      <img alt="selected pad"
                        key={index + "Image"}
                        onClick={() => setSelectedPad(index)}
                        style={{
                         
                         
                          objectFit: "contain",
                        }}
                        width={50}
                        height={50}
                        src={tab.image}
                      />
                 
                  </Stack>
                </Box>
              )}
            ></Tab> : <React.Fragment></React.Fragment>
                      })}
          <Tab 
            component={() => (
              <Box
                
                p={1}
                sx={{
                  borderTop: 1,
                  borderLeft: 1,
                  borderRight: 1,
                  borderColor: "divider",

                  minWidth: 80,
                  minHeight: 80,
                }}
              >
                <Link to={moreLink} style={{color:theme.palette.routerLink.color}}>
                  <Stack
                    direction="column"
                    justifyContent="space-around"
                    alignItems="center"
                  >
                    <Box>MORE</Box>
                    <MoreHorizIcon sx={{ fontSize: "60px" }}></MoreHorizIcon>
                  </Stack>
                </Link>
              </Box>
            )}
          ></Tab>
        </Tabs>
      </Box>
      {tabPanels.map((tabPanel, index) => (
        <TabPanel value={selectedPad} index={index} >
          {tabPanel}
        </TabPanel>
      ))}
    </BoxBordered>
  );
}
