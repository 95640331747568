import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Loading } from "../../components/loading/Loading";
import { GridContainer } from "../../components/grid/GridContainer";
import { SiteInfo } from "../../components/siteComponents/SiteInfo";
import { Grid } from "@mui/material";
import { GridItem } from "../../components/grid/GridItem";
import { SitesSelectedCoins } from "./sitesSelectedCoins";

export function SitesSelected({ siteName }) {
  const webSites = useSelector((state) => state.webSites.webSites);
  const idos = useSelector((state) => state.idos.idos);
  const [selectedSite, setSelectedSite] = useState();

  useEffect(() => {
    setSelectedSite(webSites.filter((e) => e.name === siteName)[0]);
  }, [webSites]);

  return webSites && webSites.length > 0 && idos &&
    idos.length > 0 &&
    selectedSite && idos.filter((e) => e.name === siteName).length> 0 ? (
    <GridContainer spacing={2}>
      <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
        <GridItem>
          <SiteInfo desc={true}
            seeMore={false}
            selectedSite={selectedSite}
            idos={idos.filter((e) => e.name === siteName)[0].idos}
          />
          <GridItem item xs={12}>{selectedSite.siteInfo.description}</GridItem>
            <Grid item xs={12}>
     
      </Grid>
        </GridItem>
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
        <GridItem >
          {
            webSites.length >0 && idos.length>0  ? (
              <SitesSelectedCoins  name={siteName} idos={idos} />
            ) : (
              <Loading></Loading>
            )
          }
        </GridItem>
      </Grid>
    </GridContainer>
  ) : (
    <Loading></Loading>
  );
}
