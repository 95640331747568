import React from 'react';
import {Box, CircularProgress} from "@mui/material";


export function Loading(minHeight="300px"){

    return    <Box sx={{ display: "flex" ,minHeight:minHeight , alignItems:"center" , alignContent:"center"}}>
    <CircularProgress />
  </Box>


}