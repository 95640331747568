import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";

import { TrackerToolbar } from "../../panels/app/trackerToolbar";
import { useDispatch, useSelector } from "react-redux";
import { useWeb3React } from "@web3-react/core";

import { IdoTrackerList } from "../../panels/app/idoTrackerList";
import { getChainString,  web3Objecs } from "../../../utils/solidtyInterect";
import { eventsAll } from "../../panels/app/events";
import { GridContainer } from "../../components/grid/GridContainer";
import { Helmet } from "react-helmet-async";
import { idoTrackerAbi } from "../../../constant/idoTrackerAbi";
import {
  BSC_IDO_TRACKER_CONTRACT,
  PANCAKESWAPBUY,
} from "../../../constant/addresses";

import { GridItem } from "../../components/grid/GridItem";
import { useTheme } from "@emotion/react";
import { formatTokenAllocation } from "../../../utils/utils";
import { useSearchParams } from "react-router-dom";

const fnCanUseApp = async function(setCanUseApp, account) {
  try{
    const web3 = web3Objecs["BSC"];
    const idoTracker = new web3.eth.Contract(
      idoTrackerAbi,
      BSC_IDO_TRACKER_CONTRACT
    );
    const idoTrackerCanUse = await idoTracker.methods
      .canUseAppByAddress(account)
      .call();
      setCanUseApp(idoTrackerCanUse);
  }catch(e){
    setCanUseApp(false);

  }
  
};

const fnAppUsageCount = async function(setAppUsageCount) {
  try {
  const web3 = web3Objecs["BSC"];
  const idoTracker = new web3.eth.Contract(
    idoTrackerAbi,
    BSC_IDO_TRACKER_CONTRACT
  );
  const idoTrackerAppUsageCount = await idoTracker.methods
    .getAppUsageCount()
    .call();
    setAppUsageCount(idoTrackerAppUsageCount);    
  }catch(e){
    setAppUsageCount(0);    
  }
};


const fnBalance = async function(setBalance,account) {
  try{
    const web3 = web3Objecs["BSC"];
    const idoTracker = new web3.eth.Contract(
      idoTrackerAbi,
      BSC_IDO_TRACKER_CONTRACT
    );
    const idoTrackerAppUsageCount = await idoTracker.methods
      .balanceOf(account)
      .call();
      setBalance(idoTrackerAppUsageCount);
  }catch(e){
    setBalance(0);
  }
  
};

export function TrackerPage() {
  const sites = useSelector((state) => state.webSites.webSites);
  const idos = useSelector((state) => state.idos.idos);
  const [searchParams] = useSearchParams();

  const [chain, setChain] = useState(searchParams.get("chain") || "BSC");
  const [site, setSite] = useState(searchParams.get("site")|| "ALL");
  const [ido, setIdo] = useState(searchParams.get("ido")||"ALL");
  const [caseIdo, setCaseIdo] = useState(searchParams.get("caseido")||"ALL");
  const [chainList, setChainList] = useState([]);
  const [events, setEvents] = useState([]);
  const [refreshNeeded, setRefreshNeeded] = useState(false);
  const { active, chainId, library, account } = useWeb3React();
  const [ canUseApp, setCanUseApp ] = useState();
  const [ appUsageCount, setAppUsageCount ]= useState(0);
  const [ balance, setBalance ] = useState(0);
  const theme = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    setChainList(new Set(sites.map((item) => item.whichNetworks[0])));
  }, [sites]);
  
  useEffect(() => {
    if (active) {
      fnCanUseApp(setCanUseApp, account);
    }
  }, [active, account]);

  useEffect(() => {
    if (active) {
      fnBalance(setBalance, account);
    }
  }, [active, account]);

  useEffect(() => {
    fnAppUsageCount(setAppUsageCount);
  },[]);

  useEffect(() => {
    if (canUseApp && chain === getChainString[chainId]) {
      eventsAll({
        ido: ido,
        site: site,
        idos: idos,
        account: account,
        library: library,
        chainId: chainId,
        setEvents: setEvents,
        dispatch,
      });
    }
  }, [ido, site, idos, account, library, chainId, chain,canUseApp]);

  useEffect(() => {
    if (canUseApp && chain && chainId && chain !== getChainString[chainId]) {
      setChain(getChainString[chainId]);
    }
  }, [chainId]);

  useEffect(() => {
    if (canUseApp && refreshNeeded) {
      eventsAll({
        ido: ido,
        site: site,
        idos: idos,
        account: account,
        library: library,
        chainId: chainId,
        setEvents: setEvents,
        dispatch,
      });
      setRefreshNeeded(false);
    }
  }, [caseIdo]);
  return (
    <React.Fragment>
      <Helmet>
        <title>TRACKER APP</title>
        <meta
          name="description"
          content="Track IDOS app on chain BSC, Etherium, Polygon, Velas"
        />
      </Helmet>

      {active ? (
        canUseApp ? (
          <GridContainer spacing={2}>
            <Grid item xs={12}>
              <TrackerToolbar
                chain={chain}
                setChain={setChain}
                site={site}
                setSite={setSite}
                ido={ido}
                setIdo={setIdo}
                caseIdo={caseIdo}
                setCaseIdo={setCaseIdo}
                chainList={chainList}
                sites={sites}
                idos={idos}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider orientation="horizontal"></Divider>
            </Grid>

            <Grid item xs={12}>
              <IdoTrackerList
                events={events}
                caseIdo={caseIdo}
                setRefreshNeeded={setRefreshNeeded}
              />
            </Grid>
          </GridContainer>
        ) : (
          <GridContainer>
            <Grid xs={12}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >

              <Typography>
                  Your Balance is {formatTokenAllocation( balance,18,2)}/{formatTokenAllocation( appUsageCount,18,2)}
                </Typography>
                <Typography onClick={(e) => window.open(PANCAKESWAPBUY, "_blank")} component={Link}>
                  Buy {formatTokenAllocation( appUsageCount - balance ,18,2)}  IDT Token more over PancakeSwap for use IDO Tracker
                </Typography>
                <Button
                  variant="contained"
                  component={Link}
                  to={"/app"}
                  onClick={(e) => window.open(PANCAKESWAPBUY, "_blank")}
                  sx={{ backgroundColor: theme.palette.subColor.light }}
                >
                  Buy
                </Button>
              </Stack>
            </Grid>
          </GridContainer>
        )
      ) : (
        <GridContainer>
          {" "}
          <Grid item xs={12}>
            <GridItem>
              <Box justifyContent="center" alignItems="center">
              <Typography>
                  Connect Metamask to Use Tracker APP
                </Typography>
              </Box>
            </GridItem>
          </Grid>
        </GridContainer>
      )}
    </React.Fragment>
  );
}
