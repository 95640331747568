import React from "react";
import { Box } from "@mui/material";

export function BoxBordered({
    width = "100%",
    borderBottom = 1,
    borderColor = "divider",
    ...props
  }) {

        return  <Box  width={width}  borderBottom= {borderBottom} borderColor={borderColor}  {...props}>
            {props.children}
        </Box>

  }