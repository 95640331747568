import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clipboard: "",
  globalLoading: false,
};

const reducers = {
  setClipboard: (state, action) => ({
    ...state,
    clipboard: action.payload,
  }),
  setGlobalLoading: (state, action) => ({
    ...state,
    globalLoading: {
      value:action.payload.value,
      message:action.payload.message
    }
  }),
  setGlobalLoadingSubMessage: (state, action) => ({
    ...state,
    globalLoading: {
      ...state.globalLoading , 
      subMessage:action.payload.subMessage,
    }
  }),
};

export const uiUtilsSlice = createSlice({
  name: "uiUtils",
  initialState,
  reducers: reducers,
});

// Action creators are generated for each case reducer function
export const { setClipboard,setGlobalLoading,setGlobalLoadingSubMessage } = uiUtilsSlice.actions; //action creator

export default uiUtilsSlice.reducer;
