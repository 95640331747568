import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { calcUsdtPrice } from "../../../utils/solidtyInterect";


import { LoadingLinear } from "../loading/LoadingLinear";

const getTokenPrice = async (tokenAddressin, tokenNetwork, setPrice) => {
  await calcUsdtPrice(tokenAddressin, tokenNetwork).then(price=>setPrice(price)).catch(err=>setPrice(0));
 
};

export function Price({ tokenAddress, tokenNetwork = "BSC", ...props }) {
  const [price, setPrice] = useState(null);
 

  useEffect(() => {
    // if(reserveChanged){
        setPrice(null)
     getTokenPrice(tokenAddress, tokenNetwork, setPrice);
    // setReserveChanged(false);
    // }

    // if(pairContract==null){
    //   setPairContractAsync(tokenAddress, setPairContract);
    // }

    return () => {
      
    };
  }, [tokenAddress]);

  // useEffect(() => {
  //   if (pairContract == null) return;

  //   const listener = (reserve0, reserve1) => {
  //     setReserveChanged(true);
  //   };
  //   pairContract.on("Sync", listener);

  //   return () => {
  //     pairContract.off("Sync", listener);
  //   };
  // }, [pairContract]);
  return (
    <Box align="left" px={"8px"}>
       {price != null
        ?"$ " +price
        : <LoadingLinear/>}
    </Box>
  );
}
