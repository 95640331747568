import React from "react";
import {
  Grid,
  Stack,
  Avatar,
  Card,
  CardHeader,
  Box,

  CardContent,
  Typography,
  Divider,
  CardActions,
  TableCell,
  TableBody,
  useMediaQuery,
} from "@mui/material";

import { Loading } from "../../components/loading/Loading";
import { useStylesPosition } from "../../components/css/Utility";
import { API, getIdoIconAddress } from "../../../api/constant";
import moment from "moment";
import { Link } from "react-router-dom";
import { TableBase } from "../../components/tables/TableBase";
import { TableHeadBase } from "../../components/tables/TableHeadBase";
import { currencyFormat } from "../../../utils/utils";
import { useTheme } from "@emotion/react";

export function CoinsAllBody({idos,site,searchText}) {

  const classes = useStylesPosition();
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <React.Fragment>
   
      {idos.length > 0 ? (
        idos
          .filter((e) => e.name.includes(site) || site === "ALL")
          .map((element) => {
            return element.idos
              .filter((e) =>
                e.info.name.toLowerCase().includes(searchText.toLowerCase())
              )
              .map((ido) => {
                let data = ido ; 
                let partInfo = data.participationInfo ;
                return (
                  <Grid item xs={12} md={12} lg={6} xl={4} >
                    <Card>
                      <CardHeader
                        avatar={
                          <Avatar
                            variant="square"
                            sx={{ width: isSmallScreen?"80px":"150px", height:isSmallScreen?"80px": "150px" }}
                            src={getIdoIconAddress(ido.info.logo2)}
                          ></Avatar>
                        }
                        title={
                          <Typography variant="h6" component="div">
                            {ido.info.name}
                          </Typography>
                        }
                        subheader={
                          <React.Fragment>
                            <Typography variant="body2" color="text.secondary">
                              {ido.info.description}
                            </Typography>
                            <Divider></Divider>
                            <Box
                              p={2}
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Link
                                to={
                                  "/idos/" +
                                  ido.name.split("(")[0].replace(/\s/g, "") +
                                  "/" +
                                  ido.address
                                }
                                style={{
                                  color: theme.palette.routerLink.color,
                                }}
                              >
                                See more
                              </Link>
                            </Box>
                          </React.Fragment>
                        }
                      ></CardHeader>
                      <Divider></Divider>
                      <CardContent>
                        <TableBase
                          minWidth={"300px"}
                          head={
                            <TableHeadBase
                              headList={[
                                {
                                  title: "1 " + partInfo.symbol + "",
                                  exp: "Token Sale Price",
                                },
                                {
                                  title: "IDO Date",
                                  exp: "Date of Token Sale",
                                },
                                {
                                  title: "Participated",
                                  exp: "Total Count User Participated",
                                },
                                {
                                  title:
                                    "Fund(" + data.rules.tokenPair0Name + ")",
                                  exp: "Total Fund Participated",
                                },
                              ]}
                            />
                          }
                        >
                          <TableBody>
                            <TableCell
                              className={`${classes.font}`}
                              sx={{
                                padding: "8px",
                                overflow: "hidden",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                variant="caption"
                                className={classes.overflow}
                              >
                                {partInfo.rate
                                  ? partInfo.rate.toString().slice(0, 6)
                                  : ""}{" "}
                                {data.rules.tokenPair1Name}
                              </Typography>
                            </TableCell>
                            <TableCell
                              className={`${classes.font}`}
                              sx={{ padding: "8px", alignItems: "center" }}
                            >
                              {moment(partInfo.when * 1000 || 0)
                                .utc()
                                .format("YYYY-MM-DD")}
                            </TableCell>
                            <TableCell
                              className={`${classes.font}`}
                              sx={{ padding: "8px", alignItems: "center" }}
                            >
                              {partInfo.totalCountUserParticipated}
                            </TableCell>
                            <TableCell
                              className={`${classes.font}`}
                              sx={{ padding: "8px", alignItems: "center" }}
                            >
                              {partInfo.totalFundParticipated
                                ? currencyFormat(
                                    partInfo.totalFundParticipated
                                      .toString()
                                      .split(".")[0]
                                  )
                                : ""}
                            </TableCell>
                          </TableBody>
                        </TableBase>
                      </CardContent>
                      <CardActions>
                        <Box
                          width={"100%"}
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={1}
                          >
                            <img
                              alt="Site"
                              src={
                                API.imageUrl +
                                API.staticSites +
                                encodeURIComponent(
                                  element.name.replace(/\s/g, "")
                                )
                              }
                              width={"20px"}
                              height={"20px"}
                            ></img>
                            <Typography variant="caption">
                              {element.name}
                            </Typography>
                          </Stack>
                        </Box>
                      </CardActions>
                    </Card>
                  </Grid>
                );
              });
          })
      ) : (
        <Loading></Loading>
      )}
    </React.Fragment>
  );
}
