import React from 'react';
import {Box} from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';

export function LoadingLinear(){

    return    <Box sx={{ width: '100%' }}>
    <LinearProgress />
  </Box>


}