import React, { useEffect, useState } from "react";
import {
  AppBar,
  Badge,
  Box,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";

import Menu from "@mui/material/Menu";

import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import WebIcon from "@mui/icons-material/Web";
import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin";
import InfoIcon from "@mui/icons-material/Info";
import styled from "@emotion/styled";
import { useDrawerContext } from "./drawerContext";
import { useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import { MetamaskConnect } from "../components/blockchain/MetamaskConnect";

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
});



const Icons = styled(Box)(({ theme }) => ({
  display: "none",
  alignItems: "center",
  gap: "20px",
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const IconsSmall = styled(Box)(({ theme }) => ({
  display: "none",
  alignItems: "center",
  gap: "20px",
  [theme.breakpoints.down("md")]: {
    display: "flex",
  },
}));

const InfoIconSmall = styled(InfoIcon)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.down("md")]: {
    display: "flex",
  },
}));

const MenuStyled = styled(Menu)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.down("md")]: {
    display: "flex",
  },
}));

const badgeStyle = {
  "& .MuiBadge-badge": {
    color: "#FFFFFF",
    backgroundColor: "#f57c00",
  },
};

export function NavBar() {
  const { isOpened, toggleIsOpened } = useDrawerContext();
  const theme = useTheme();
  const webSites = useSelector((state) => state.webSites.webSites);
  const idos = useSelector((state) => state.idos.idos);
  const [participant, setParticipant] = useState(0);
  const [idoCount, setIdoCount] = useState(0);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  useEffect(() => {
    let count = 0;
    let participantCount = 0;
    idos.forEach((element) => {
      count += element.idos.length;
      element.idos.forEach((ido) => {
        participantCount += Number(
          ido.participationInfo.totalCountUserParticipated
        );
      });
    });
    setIdoCount(count);
    setParticipant(participantCount);
  }, [webSites, idos]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="fixed"
      sx={{ backgroundColor: theme.palette.appBar.backgroundColor }}
    >
      <StyledToolbar>
        <Stack direction="row" spacing={1} justifyContent={"flex-start"} alignItems={"center"}>
        
          {isSmallScreen ? (
            <React.Fragment></React.Fragment>
          ) : (
            <MenuIcon onClick={() => toggleIsOpened(!isOpened)} />
          )}
          
          <Typography
            variant="h6"
            sx={{ display: { xs: "none", sm: "none", md: "block" } }}
          >
            IDO TRACKER
          </Typography>
          
          
        </Stack>
        <Stack
          height={"100%"}
          direction="column"
          justifyContent="center"
          alignItems="center"
          useFlexGap
          flexWrap="wrap"
        >
          <Box maxWidth={"200px"}>
            <MetamaskConnect></MetamaskConnect>
          </Box>
        </Stack>
        {/* <Search>
          <InputBase
            placeholder="search webpage,ido with name or address..."
            sx={{
              width: "100%",
              input: {
                color: "black",
                "&::placeholder": {
                  opacity: 0.5,
                },
              },
            }}
          />
        </Search> */}
        <Icons>
          <Tooltip title={"Total Ido Web Site:" + webSites.length} max={999}>
            <Badge badgeContent={webSites.length} sx={badgeStyle}>
              <WebIcon />
            </Badge>
          </Tooltip>
          <Tooltip title={"Total Participant Count:" + participant} max={9999}>
            <Badge badgeContent={participant} sx={badgeStyle}>
              <PeopleOutlineIcon />
            </Badge>
          </Tooltip>
          <Tooltip title={"Total IDO count:" + idoCount} max={999}>
            <Badge badgeContent={idoCount} sx={badgeStyle}>
              <CurrencyBitcoinIcon />
            </Badge>
          </Tooltip>
        </Icons>

        <MenuStyled
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <IconsSmall>
            <Tooltip title={"Total Ido Web Site:" + webSites.length} max={999}>
              <Badge badgeContent={webSites.length} sx={badgeStyle}>
                <WebIcon />
              </Badge>
            </Tooltip>
            <Tooltip
              title={"Total Poeple Who Joined:" + participant}
              max={9999}
            >
              <Badge badgeContent={participant} sx={badgeStyle}>
                <PeopleOutlineIcon />
              </Badge>
            </Tooltip>
            <Tooltip title={"Total IDO count:" + idoCount} max={999}>
              <Badge badgeContent={idoCount} sx={badgeStyle}>
                <CurrencyBitcoinIcon />
              </Badge>
            </Tooltip>
          </IconsSmall>
        </MenuStyled>

        <InfoIconSmall onClick={handleClick}></InfoIconSmall>
      </StyledToolbar>
    </AppBar>
  );
}
