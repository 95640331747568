import React from "react";

import { IdoList } from "../../components/siteComponents/IdoList";
export const HomeWebSiteSelectedTokensList = ({ idos, website  }) => {
  

  return (
    <IdoList idos={idos} website={website}/>
  );
};
