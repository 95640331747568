import React from "react";
import {
  Avatar,
  Box,
  Card,
 
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormControlLabel,

  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { useSelector } from "react-redux";


import {
  getCoinBrainCoinTickerUrl,
} from "../../../utils/solidtyInterect";
import { useTheme } from "@emotion/react";
import { getIdoIconAddress } from "../../../api/constant";
import { InfoLinks } from "../../components/link/InfoLinks";
import LanguageIcon from "@mui/icons-material/Language";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";
import { currencyFormat } from "../../../utils/utils";
import { CoinSelectedInfoIdosTable } from "./coinsSelectedInfoIdosTable";

const handleChange = (event, setSelectedPad) => {
  setSelectedPad(event.target.value);
};

const getCoinBrainUrl = (ido, selectedPad, mode) => {
  return getCoinBrainCoinTickerUrl(
    ido.sites[selectedPad].network,
    ido.sites[selectedPad].tokenAddress,
    mode
  );
};

export function CardMinHeight({ ...props }) {
  return <Card style={{ minHeight: "200px" }}>{props.children}</Card>;
}
export function CoinSelectedInfo({ name, ido, selectedPad, setSelectedPad }) {
  const theme = useTheme();
  const idos = useSelector((state) => state.idos.idos);


  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <React.Fragment>
      <Grid item xs={12} lg={4}>
        <CardMinHeight>
          <CardHeader
            avatar={
              <Avatar
                variant="square"
                sx={{ width: isSmallScreen ? "80px" :  "150px", height: isSmallScreen ? "80px" :"150px" }}
                src={getIdoIconAddress(ido.info.logo2)}
              ></Avatar>
            }
            title={
              <Typography variant="h6" component="div">
                {name}
              </Typography>
            }
            subheader={
              <Typography variant="body2" color="text.secondary">
                {ido.info.description}
              </Typography>
            }
          ></CardHeader>
        </CardMinHeight>
      </Grid>
      <Grid item xs={12} lg={4}>
        <CardMinHeight>
          <CardHeader
            title={
              <Typography variant="h6" component="div">
                Total Values
              </Typography>
            }
          ></CardHeader>
          <CardContent>
            <Box direction="column" justifyContent="center" alignItems="center">
              <Typography variant="body2" color="text.secondary">
                Total Participated:{ido.totalCountUserParticipated}
              </Typography>

              <Typography variant="body2" color="text.secondary">
                Total Fund Participated:{" "}
                {currencyFormat(
                  ido.totalFundParticipated.toString().split(".")[0]
                )}{" "}
                $
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Total Funded Site:{ido.sites.length}
              </Typography>
            </Box>
          </CardContent>
        </CardMinHeight>
      </Grid>
      <Grid item xs={12} lg={4}>
        <CardMinHeight>
          <CardHeader
            title={
              <Typography variant="h6" component="div">
                Links
              </Typography>
            }
          ></CardHeader>
          <CardContent>
            <InfoLinks
              max={3}
              info={[
                {
                  tooltip: "website",
                  Icon: (
                    <LanguageIcon
                      onClick={() => window.open(ido.info.website, "_blank")}
                    ></LanguageIcon>
                  ),
                },
                {
                  tooltip: "twitter",
                  Icon: (
                    <TwitterIcon
                      onClick={() => window.open(ido.info.twitter, "_blank")}
                    ></TwitterIcon>
                  ),
                },
                {
                  tooltip: "telegram",
                  Icon: (
                    <TelegramIcon
                      onClick={() => window.open(ido.info.telegram, "_blank")}
                    ></TelegramIcon>
                  ),
                },
              ]}
            />
          </CardContent>
        </CardMinHeight>
      </Grid>
      <Grid item xs={12} lg={12} xl={12}>
        <Card>
          <CardHeader
            title={
              <Typography variant="h6" component="div">
                Published Sites
              </Typography>
            }
            subheader={
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={theme.spacing(1)}
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={selectedPad}
                    onChange={(event) => handleChange(event, setSelectedPad)}
                  >
                    {ido.sites.map((e, i) => (
                      <FormControlLabel
                        value={i}
                        control={<Radio />}
                        label={e.siteName}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>

                
              </Stack>
            }
          ></CardHeader>
          <Divider></Divider>
          <CardContent>
          <CoinSelectedInfoIdosTable
                  idos={idos}
                  website={ido.sites[selectedPad].siteName}
                  idoSites={ido.sites}
                />
          </CardContent>
        
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <iframe 
            width="100%"
            height="172" title="Coin Info Coin Brain"
            src={getCoinBrainUrl(ido, selectedPad, theme.palette.mode)}
          ></iframe>
        </Card>
      </Grid>
    </React.Fragment>
  );
}
