import React from "react";
import {
  Grid,
  Stack,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
  TextField,


} from "@mui/material";



import SearchIcon from "@mui/icons-material/Search";


export function SitesAllHead({sites, setSite ,site ,  setSearchText }) {


  const handleSite = (event) => {
    setSite(event.target.value);
  };
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };


  return (
      <Grid item xs={12}>
        
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
         
          >
            <Box>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="simple-select-label">Web Site</InputLabel>
                <Select
                  labelId="simple-select-label"
                  id="simple-select"
                  value={site}
                  label="Web Site"
                  onChange={handleSite}
                >
                  <MenuItem value={"ALL"}>ALL</MenuItem>
                  {sites.map((site) => (
                    <MenuItem value={site.name}>{site.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <TextField
                  onChange={handleSearch}
                  label="Search..."
                  variant="standard"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Box>
          </Stack>
       
      </Grid>
  );
}
