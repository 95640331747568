import React from "react";
import { Table  } from "@mui/material";
import { TableContainerBase } from "./TableContainerBase";


export function TableBase({ headList, minWidth="1000px",tableLayout,head , ...props }) {
 
  return (
    <TableContainerBase>
      <Table
        sx={{ tableLayout: tableLayout, minWidth: minWidth }}
      >
       
          {head}
        
         {props.children}
       
      </Table>
    </TableContainerBase>
  );
}
