import { useEffect } from "react";
import IdoTrackerService from "../api/service";
import { useDispatch, useSelector } from "react-redux";
import { setIdos } from "../stateManagement/slices/app/idos";
import { setGlobalLoading } from "../stateManagement/slices/ui/uitls";

export const useSiteIdos = () => {
  const dispatch = useDispatch();
  const sites = useSelector((state) => state.webSites.webSites);

  useEffect(() => {
    const getSiteIDOs = async () => {
      let allIdos = [] ;
      dispatch(setGlobalLoading({value:true , message:"Loading IDOs..."}));
      let idos = await IdoTrackerService.getAllSitesIDOs();
      for (const element of sites) {
       
        try {
          
          allIdos.push({
            idos: idos.Items.filter(f=>f.secondId.includes(element.name)).sort((a, b) =>
              a.participationInfo.when > b.participationInfo.when
                ? -1
                : b.participationInfo.when > a.participationInfo.when
                ? 1
                : 0
            ),
            name: element.name,
          })
          
        } catch (e) {
          console.log("error->useSiteIdos",e) ;
        }
      }
      dispatch(
        setIdos(allIdos)
      );
      dispatch(setGlobalLoading(false));
    };
    if(sites && sites.length>0){
      getSiteIDOs();
    }
    
  }, [sites]);
};
