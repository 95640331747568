import React from "react";
import {
  Stack,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";




import {  API } from "../../../api/constant";
import moment from "moment";

import { currencyFormat } from "../../../utils/utils";


import { TokenAddressWithLinks } from "../../components/blockchain/TokenAddressWithLinks";
import { useStylesStickColumnTable } from "../../components/css/Utility";
import { Loading } from "../../components/loading/Loading";
import { useTheme } from "@emotion/react";

export const CoinsSelectedInfoIdosTableRow = ({ i, data, website }) => {
  const classes = useStylesStickColumnTable();
  let partInfo = data.participationInfo ;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return partInfo ? (
    <TableRow >
      <TableCell
        sx={{ padding: "8px" ,maxWidth:isSmallScreen ? "60px" : "120px" }}
        
        align="left"
        className={`${classes.sticky} ${classes.font} ${classes.overflow}  `}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0.5}
        >
          <img alt="Web Site"
            src={
              API.imageUrl +
              API.staticIdos +
              website +
              "/" +
              encodeURIComponent(data.name.replace(/\s/g, ""))
            }
            width={"30px"}
            height={"30px"}
          ></img>
          <Typography variant="caption" className={classes.overflow}>
            {data.name}
          </Typography>
        </Stack>
      </TableCell>

  

      <TableCell
        className={`${classes.font}`}
        sx={{ padding: "8px", overflow: "hidden" ,maxWidth:"60px" }}
      >
        <Typography variant="caption" className={classes.overflow}>
          1 {partInfo.symbol} ={" "}
          {partInfo.rate ? partInfo.rate.toString().slice(0, 6) : ""}{" "}
          {data.rules.tokenPair1Name}
        </Typography>
      </TableCell>
      <TableCell className={`${classes.font}`} sx={{ padding: "8px" }}>
        {moment(partInfo.when * 1000 || 0)
          .utc()
          .format("YYYY-MM-DD")}
      </TableCell>
      <TableCell className={`${classes.font}`} sx={{ padding: "8px" }}>
        {partInfo.totalCountUserParticipated}
      </TableCell>
      <TableCell className={`${classes.font}`} sx={{ padding: "8px" }}>
        {partInfo.totalFundParticipated
          ? currencyFormat(
              partInfo.totalFundParticipated.toString().split(".")[0]
            ) +
            " " +
            data.rules.tokenPair0Name
          : ""}
      </TableCell>

      <TableCell sx={{ padding: "8px" }}>
      <TokenAddressWithLinks
                            isMetamask={true}
                            isScanSite={true}
                            addresses={[{tokenAddress:data.address , network:data.network}]}
                          ></TokenAddressWithLinks>
      </TableCell>

      <TableCell sx={{ padding: "8px" }}>
      <TokenAddressWithLinks scanType="address"
                            isMetamask={false}
                            isScanSite={true}
                            addresses={[{tokenAddress:data.contractMethods.address , network:data.network}]}
                          ></TokenAddressWithLinks>
      
      </TableCell>
     
    </TableRow>
  ) : (
    <Loading></Loading>
  );
};
