import React from 'react';

import { useParams} from "react-router-dom";
import { SitesSelected } from '../../panels/site/sitesSelected';
import { Helmet } from 'react-helmet-async';



export function Site() {

    let { siteName } = useParams();

    return <React.Fragment>
 <Helmet>
        <title>Site-{siteName} </title>
        <meta name='description' content={siteName + ' from BLuezilla web sites'} />
      </Helmet>
<SitesSelected siteName={siteName}>



</SitesSelected>
    
    </React.Fragment>


}

