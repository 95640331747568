import React from 'react';

import { useParams} from "react-router-dom";
import { CoinSelected } from '../../panels/ido/coinsSelected';
import { Stack } from '@mui/material';
import { Helmet } from 'react-helmet-async';



export function Coin() {

    let { name,address } = useParams();
   

    return <React.Fragment>
     <Helmet>
        <title>IDO- {name} </title>
        <meta name='description' content='coins published on BSCPAD, ETHPAD, POLYPAD, METAVPAD and All BLuezilla web sites' />
      </Helmet>
      <Stack
    direction="column"
    justifyContent="center"
    alignItems="center"
    spacing={0.5}
    px={1}
  > <CoinSelected address={address} name={name}>

    </CoinSelected></Stack>
    </React.Fragment> 


}

