import request from '.'

async function  get(url) {
    return request({
        url:    url,
        method: 'GET'
    });
}

async function post(url, data) {
    return request({
        url:    url,
        method: 'POST',
        data:   data
    });
}


const Service = {
    get, post //, update, delete, etc. ...
}

export default Service;