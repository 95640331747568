import React from "react";
import {
  Grid,
  TableRow,
  TableCell,
  Stack,
  TableBody,
  Typography,
  AvatarGroup,
  Tooltip,
  Avatar,
  Box,
  useMediaQuery,
} from "@mui/material";

import { Loading } from "../../components/loading/Loading";
import { useSelector } from "react-redux";
import { GridContainer } from "../../components/grid/GridContainer";
import { TableHeadSticky } from "../../components/tables/TableHeadSticky";
import { GridItem } from "../../components/grid/GridItem";
import { TableBase } from "../../components/tables/TableBase";

import { useTheme } from "@emotion/react";
import { currencyFormat } from "../../../utils/utils";
import { useStylesStickColumnTable } from "../../components/css/Utility";
import { API, getSiteIconAddressByName } from "../../../api/constant";
import { InfoLinks } from "../../components/link/InfoLinks";
import LanguageIcon from "@mui/icons-material/Language";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";
import moment from "moment";
import { TokenAddressWithLinks } from "../../components/blockchain/TokenAddressWithLinks";
import { Link } from "react-router-dom";

export function HomeCoins() {
  const coins = useSelector((state) => state.idos.idosGrouped);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStylesStickColumnTable();
  return (
    <GridContainer spacing={isSmallScreen?0:1} >
      <Grid item xs={12}>
        <GridItem>
          {Object.keys(coins).length > 0 ? (
            <TableBase tableLayout="fixed"
              head={
                <TableHeadSticky
                  headList={[
                    { title: "Name", exp: "IDO Name" },
                    { title: "IDO Date", exp: "Date of Token Sale" },
                    {
                      title: "Total Participated",
                      exp: "Total Count User Participated",
                    },
                    {
                      title: "Total Funded(USD)",
                      exp: "Total Fund Participated",
                    },
                    { title: "Token Address", exp: "Token Address" },
                    {
                      title: "Sites",
                      exp: "Published Sites for IDO",
                    },
                    { title: "Links", exp: "All Links about IDO" },
                  ]}
                />
              }
            >
              <TableBody>
                {Object.entries(coins)
                  .slice(0, 8)
                  .map((entry, i) => {
                    const [key, value] = entry;

                    return (
                      <TableRow>
                        <TableCell
                          sx={{ padding: theme.spacing(1) }}
                          className={`${classes.sticky} ${classes.font} ${classes.overflow}  `}
                        ><Link to={"/idos/" + value.info.name.split("(")[0].replace(/\s/g, "") + "/"+ value.sites[0].tokenAddress}>
                          <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={0.5}
                          >
                            <img
                              alt="Web Site"
                              src={
                                API.imageUrl +
                                "/" +
                                encodeURIComponent(value.info.logo2)
                              }
                              width={"30px"}
                              height={"30px"}
                            ></img>
                            <Typography
                              variant="caption"
                              className={classes.overflow}
                            >
                              {key}
                            </Typography>
                          </Stack></Link>
                        </TableCell>
                        <TableCell
                          className={`${classes.font}`}
                          sx={{ padding: theme.spacing(1) }}
                        >
                          {moment(value.when * 1000 || 0)
                            .utc()
                            .format("YYYY-MM-DD")}
                        </TableCell>
                        <TableCell
                          className={`${classes.font}`}
                          sx={{ padding: theme.spacing(1) }}
                        >
                          {value.totalCountUserParticipated}
                        </TableCell>
                        <TableCell
                          className={`${classes.font}`}
                          sx={{ padding: theme.spacing(1) }}
                        >
                          {currencyFormat(value.totalFundParticipated)}
                        </TableCell>
                        <TableCell>
                          <TokenAddressWithLinks
                            isMetamask={true}
                            isScanSite={true}
                            addresses={value.sites}
                          ></TokenAddressWithLinks>
                        </TableCell>
                        <TableCell>
                          <AvatarGroup
                            max={4}
                            sx={{
                              justifyContent: "flex-end",
                              "& .MuiAvatar-root": {
                                width: 24,
                                height: 24,
                                fontSize: 12,
                              },
                            }}
                          >
                            {value.sites.map((site) => {
                              return (
                                <Tooltip
                                  title={site.siteName + "-" + site.network}
                                  sx={{ marginLeft: "-8px" }}
                                >
                                  <Avatar
                                    sx={{ width: 24, height: 24 }}
                                    src={getSiteIconAddressByName(site.siteName)}
                                  ></Avatar>
                                </Tooltip>
                              );
                            })}
                          </AvatarGroup>
                        </TableCell>
                        <TableCell sx={{ padding: "8px" }}>
                          <InfoLinks
                            info={[
                              {
                                tooltip: "website",
                                Icon: (
                                  <LanguageIcon
                                    onClick={() =>
                                      window.open(value.info.website, "_blank")
                                    }
                                  ></LanguageIcon>
                                ),
                              },
                              {
                                tooltip: "twitter",
                                Icon: (
                                  <TwitterIcon
                                    onClick={() =>
                                      window.open(value.info.twitter, "_blank")
                                    }
                                  ></TwitterIcon>
                                ),
                              },
                              {
                                tooltip: "telegram",
                                Icon: (
                                  <TelegramIcon
                                    onClick={() =>
                                      window.open(value.info.telegram, "_blank")
                                    }
                                  ></TelegramIcon>
                                ),
                              },
                            ]}
                            max={3}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}

               
              </TableBody>
            </TableBase>
          ) : (
            <Loading></Loading>
          )}
           <Box direction="row" justifyContent="center" alignItems="center">
            <Link to={"/idos"} style={{color:theme.palette.routerLink.color}}>See more</Link>
          </Box>
        </GridItem>
       
         
        
      </Grid>
    </GridContainer>
  );
}
