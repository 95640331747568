import React from "react";

import { IdoList } from "../../components/siteComponents/IdoList";

export function SitesSelectedCoins({ name, idos }) {
  return (
    <IdoList
      sliceLength={idos.filter((ido) => ido.name === name)[0].idos.length}
      idos={idos.filter((ido) => ido.name === name)[0].idos}
      website={name} seeMore ={false}
    ></IdoList>
  );
}
