import React from "react";

import { GridContainer } from "../components/grid/GridContainer";
import {
  Avatar,
  AvatarGroup,
  Box,

  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";
import { GridItem } from "../components/grid/GridItem";
import { useTheme } from "@emotion/react";
function Footer() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <GridContainer sx={{ position: "relative", bottom: 0, width: "100%" }}>
      <GridItem sx={{width: "100%" }}>
        <Stack
          sx={{ width: "100%" }}
          direction="row"
          justifyContent={isSmallScreen ?"center" : "center"}
          alignItems="center"
          spacing={2}
        >
          <Typography variant="caption">
            &copy;{new Date().getFullYear()} IDO-Tracker All rights reserved
          </Typography>

          <Box>
            <AvatarGroup max={3} sx={{ justifyContent: "flex-end" }}>
              <Tooltip title={"twitter"} sx={{ marginLeft: "-8px" }}>
                <Avatar sx={{ width: 24, height: 24 }}>
                  <TwitterIcon
                    onClick={() =>
                      window.open("https://twitter.com/IdotrackerEasy", "_blank")
                    }
                  ></TwitterIcon>
                </Avatar>
              </Tooltip>
              <Tooltip title={"telegram"} sx={{ marginLeft: "-8px" }}>
                <Avatar sx={{ width: 24, height: 24 }}>
                  <TelegramIcon
                    onClick={() =>
                      window.open("https://t.me/idotrackercom", "_blank")
                    }
                  ></TelegramIcon>
                </Avatar>
              </Tooltip>
            </AvatarGroup>
          </Box>
        </Stack>
      </GridItem>
    </GridContainer>
  );
}

export default Footer;
