import React from "react";
import {  TableCell, TableHead, TableRow,  Typography } from "@mui/material";




export function TableHeadBase({ headList , preRow,postRow ,align="left" }) {
  return (

        <TableHead>
          {preRow}
          <TableRow key={"row"}>
            {headList.map((head, i) => {
                return <TableCell key={i} align={align} sx={{fontWeight:"bold" , padding:"8px"}}  title={head.exp} >
                   <Typography variant="subtitle1">{head.title}</Typography>
                 
                  </TableCell>;
              })}
          </TableRow>
         {postRow}
        </TableHead>

      
 
  );
}
