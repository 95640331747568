import React, {  useState } from "react";
import { Box, CircularProgress, Grid } from "@mui/material";
import { GridItem } from "../../components/grid/GridItem";
import { GridContainer } from "../../components/grid/GridContainer";
import { HomeWebSiteSelectedInfo } from "./homeWebSiteSelectedInfo";
import { HomeWebSitesTabPanel } from "./homeWebSitesTabPanel";
import { Loading } from "../../components/loading/Loading";
import { useSelector } from "react-redux";

export function HomeWebSites() {
  const [selectedPad, setSelectedPad] = useState(0);
  const webSites = useSelector((state)=> state.webSites.webSites);
  const idos = useSelector((state)=> state.idos.idos);

  return (
    <GridContainer>
      <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
        <GridItem>
          { (webSites.length >0  && idos.length>0 && idos.filter(e=>e.name===webSites[selectedPad].name).length>0 )  ? (
            <HomeWebSiteSelectedInfo
              selectedSite={webSites[selectedPad]}
              idos={idos.filter(e=>e.name===webSites[selectedPad].name)[0].idos}
            ></HomeWebSiteSelectedInfo>
          ) : (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          )}
        </GridItem>
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={9} xl={10}>
        <GridItem >
          {
            webSites.length >0 && idos.length>0  ? (
              <HomeWebSitesTabPanel selectedPad={selectedPad} setSelectedPad={setSelectedPad} webSites={webSites} idos={idos} ></HomeWebSitesTabPanel>
            ) : (
              <Loading></Loading>
            )
          }
        </GridItem>
      </Grid>
    </GridContainer>
  );
}
