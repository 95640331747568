import React from "react";
import { TableBase } from "../../components/tables/TableBase";
import { TableHeadSticky } from "../../components/tables/TableHeadSticky";
import { TableBody } from "@mui/material";
import { CoinsSelectedInfoIdosTableRow } from "./coinsSelectedInfoIdosTableRow";


export function CoinSelectedInfoIdosTable({ idos, website, idoSites }) {

  const idosSelected = idos
    .filter((e) => {
      return e.name === website;
    })[0]
    .idos.filter((e) => {
      return idoSites.some((f) => f.tokenAddress === e.address);
    });
  return (
    <TableBase
      head={
        <TableHeadSticky
          headList={[
            { title: "Name", exp: "IDO Name" },
            { title: "IDO Price", exp: "Token Sale Price" },
            { title: "IDO Date", exp: "Date of Token Sale" },
            {
              title: "Participated",
              exp: "Total Count User Participated",
            },
            { title: "Fund", exp: "Total Fund Participated" },
            { title: "Token Address", exp: "Token Address" },
            {
              title: "P. Address",
              exp: "Participant Contract Address for IDO",
            },
          ]}
        />
      }
    >
      <TableBody>
        {idosSelected.map((data, i) => {
          return (
            <CoinsSelectedInfoIdosTableRow
              i={i}
              data={data}
              website={website}
            ></CoinsSelectedInfoIdosTableRow>
          );
        })}
      </TableBody>
    </TableBase>
  );
}
