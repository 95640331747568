import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";

import { routes } from "./ui/constant/routes";
import { HelmetProvider } from 'react-helmet-async';
import {
  Backdrop,
  Box,
  CircularProgress,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import { LeftSide } from "./ui/layout/LeftSide";
import { MainSide } from "./ui/layout/MainSide";
import { NavBar } from "./ui/layout/Navbar";
import { DrawerContextProvider } from "./ui/layout/drawerContext";

import { useSites } from "./hooks/useSites";
import { useSiteIdos } from "./hooks/useSitesIdos";
import { Web3ReactProvider } from "@web3-react/core";
import Web3 from "web3";
import { useSelector } from "react-redux";
import { GridItem } from "./ui/components/grid/GridItem";
import Footer from "./ui/layout/Footer";
import { useSiteIdosGroupBy } from "./hooks/useSitesGroupBy";

function getLibrary(provider) {
  return new Web3(provider);
}
const routeComponents = routes.map(({ path, component }, key) => (
  <Route exact path={path} element={component} key={key} />
));

function App() {
  const [mode, setMode] = useState("dark");
  useSites();
  useSiteIdos();
  useSiteIdosGroupBy();
  const globalLoading = useSelector((state) => state.ui.globalLoading);
 
  const idoTrackerTheme = createTheme({
    palette: {
      mode: mode,
      appBar: {
        backgroundColor: mode === "light" ? "#1e4968" : "#121212",
      },
      routerLink: {
        color: mode === "light" ? "#5190bf" : "#ffb74d",
      },
      background: {
        
        paper: mode === "light" ?"#f2f9ff" : "#121212",
      },
      subColor: {
        main: mode === "light" ? "#5190bf" : "#ffb74d",
        light: mode === "light" ? "#5190bf" : "#ffb74d",
        dark: mode === "light" ? "#5190bf" : "#ffb74d",
      },

    },
    tableHead: {
      fontFamily: ["Georgia", "serif"].join(","),
    },
    fab: {
      color: mode === "light" ? "primary" : "warning",
    }
  });

  return (
    <HelmetProvider>
    <ThemeProvider theme={idoTrackerTheme}>
      <DrawerContextProvider>
        <Web3ReactProvider getLibrary={getLibrary}>
          <Box sx={{minHeight:"100vh"}}
            bgcolor={"background.default"}
            color={"text.primary"}
          >
            <NavBar />

            <LeftSide mode={mode} setMode={setMode}></LeftSide>
            <MainSide mode={mode} setMode={setMode}>
              <Routes>{routeComponents}</Routes>
              
            </MainSide>
            
          </Box>
          <Footer ></Footer>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={globalLoading?globalLoading.value:false}
          >
            <GridItem>
              <Typography>{globalLoading.message}</Typography>
              <Typography variant="caption">
                {globalLoading.subMessage}
              </Typography>

              <CircularProgress color="inherit" />
            </GridItem>
          </Backdrop>
        </Web3ReactProvider>
      </DrawerContextProvider>
    </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
