import { Grid, useMediaQuery  } from "@mui/material";
import React from "react";

import { OptionsApp } from "./optionsApp";
import { useTheme } from "@emotion/react";



export const TrackerToolbar = ({
  chain,
  setChain,
  site,
  setSite,
  ido,
  setIdo,
  caseIdo,
  setCaseIdo,
  chainList,
  sites,
  idos
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container xs={12} spacing={isSmallScreen ? 0 : 2}>
      <Grid item xs={12}  pb={1}>
        <OptionsApp
          chain={chain}
          setChain={setChain}
          site={site}
          setSite={setSite}
          ido={ido}
          setIdo={setIdo}
          caseIdo={caseIdo}
          setCaseIdo={setCaseIdo}
          chainList={chainList}
          sites={sites}
          idos={idos}
       
        ></OptionsApp>
      </Grid>
    
    </Grid>
  );
};
