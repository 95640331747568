import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  webSites: [],

}

const reducers = {
    setWebSites:(state, action) => ({
        ...state,
        webSites:  action.payload.webSites
    }),
   
}

export const webSitesSlice = createSlice({
  name: 'websites',
  initialState,
  reducers: reducers,
})


// Action creators are generated for each case reducer function
export const { setWebSites} = webSitesSlice.actions //action creator

export default webSitesSlice.reducer