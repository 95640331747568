import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { setIdosGroupBy } from "../stateManagement/slices/app/idos";
import { setGlobalLoading } from "../stateManagement/slices/ui/uitls";

export const useSiteIdosGroupBy = () => {
  const dispatch = useDispatch();
  const idos = useSelector((state) => state.idos.idos);

  useEffect(() => {

    const setSiteIDOsGroupBy = async () => {
      dispatch(
        setIdosGroupBy()
      );
      dispatch(setGlobalLoading(false));
    };
    if(idos && idos.length>0){
      dispatch(setGlobalLoading({value:true , message:"Grouping IDOs..."}));
      setSiteIDOsGroupBy();
    }
  
   
   
  }, [idos]);
};
