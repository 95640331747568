const exactMath = require("exact-math");
export function currencyFormat(num) {
  return Number(num)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export const groupBy = (items, key) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {}
  );

export const toFixedPrice = function(val, decimal = 18) {
  let retVal = Number(val)
    .toFixed(decimal)
    .toString();
  retVal = Number(retVal)
    .toFixed(Math.max(4, getNonZeroDecimalPosition(retVal.toString())) + 2)
    .toString();
  return retVal;
};
function getNonZeroDecimalPosition(v) {
  var v2 = v.replace(/\.(0+)?/, "");
  return v2.length !== v.length ? v.length - v2.length : -1;
}

export const truncateAddress = (address) => {
  if (!address) return "No Account";
  const match = address.match(
    /^(0x[a-zA-Z0-9]{2})[a-zA-Z0-9]+([a-zA-Z0-9]{2})$/
  );
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

const formatNumberDownRoundWithExtractMax = (number, decimal) => {
  return exactMath
    .div(
      exactMath.floor(exactMath.mul(number, exactMath.pow(10, decimal))),
      exactMath.pow(10, decimal)
    )
    .toFixed(decimal);
};

export const arrayUniqueByKey = function (array,key){
 
  const arrayUnique = [...new Map(array.map(item =>
    [item[key], item])).values()];
    return arrayUnique ;


} 

export const formatTokenAllocation = (number, decimals, decimalDisplay) => {
  if (typeof number === "number") {
    return `${formatNumberDownRoundWithExtractMax(number / 10 ** decimals ,decimalDisplay )}`;
  }
  if (typeof number === "string") {
    if (number.includes("-")) {
      const tempArr = number.split("-");
      if (tempArr.length >= 2) {
        const item1 = formatNumberDownRoundWithExtractMax(
          tempArr[0] / 10 ** decimals || 0,
          decimalDisplay
        );
        const item2 = formatNumberDownRoundWithExtractMax(
          tempArr[1] / 10 ** decimals || 0,
          decimalDisplay
        );
        return `${item1} / ${item2}`;
      }
    } else {
      return `${formatNumberDownRoundWithExtractMax(number / 10 ** decimals ,decimalDisplay )}`;
    }
  }
};
