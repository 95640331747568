import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Loading } from "../../components/loading/Loading";
import { GridContainer } from "../../components/grid/GridContainer";

import { CoinSelectedGraph } from "./coinSelectedGraph";
import { CoinSelectedInfo } from "./coinsSelectedInfo";

export function CoinSelected({ name,address }) {
  const idosGrouped = useSelector((state) => state.idos.idosGrouped);
  const [selectedIdoGrouped, setSelectedIdoGrouped] = useState();
  const [selectedPad, setSelectedPad] = useState(0);

  useEffect(() => {
    setSelectedIdoGrouped(idosGrouped[name]);
  }, [idosGrouped]);



  return selectedIdoGrouped ? (
    <GridContainer spacing={2}>
      <CoinSelectedInfo name={name}  ido={selectedIdoGrouped} selectedPad={selectedPad}  setSelectedPad={setSelectedPad}/>
      
      <CoinSelectedGraph ido={selectedIdoGrouped} selectedPad={selectedPad} setSelectedPad={setSelectedPad}/>
    </GridContainer>
  ) : (
    <Loading></Loading>
  );
}
