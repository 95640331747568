import React from "react";



import { HomeInfo } from "../../panels/home/homeInfo";

import {  Stack } from "@mui/material";
import { HomeWebSites } from "../../panels/home/homeWebSites";
import { HomeCoins } from "../../panels/home/homeCoins";
import { Helmet } from "react-helmet-async";




export function HomePage() {
  return (
    <React.Fragment>
     <Helmet>
        <title>IDO Tracker</title>
        <meta name='description' content='IDOS published on BSCPAD, ETHPAD, POLYPAD, METAVPAD and All BLuezilla web sites' />
        <meta
          name="keywords"
          content="ido tracker,IDO Tracker, Ido Tracker, bluezilla, bscpad, metavpad, gamezone, aipad"
        />
      </Helmet>
      <Stack
  direction="column"
  justifyContent="center"
  alignItems="center"
  spacing={0.5} px={1}
>
        <HomeInfo></HomeInfo>
        <HomeWebSites></HomeWebSites>
      
        <HomeCoins></HomeCoins>
    </Stack>
    
</React.Fragment>
  );
}
