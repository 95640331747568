import Service from '../base/service'
import {API} from '../constant'



async function getSites() {
    return await Service.get(API.sites);
}



async function getSitesIDOs(siteName) {
    return await Service.post(API.siteIDOs , {"site":siteName} );
}
async function getAllSitesIDOs() {
    return await Service.post(API.siteIDOsAll  );
}


const IdoTrackerService = {
    getSites, getSitesIDOs,getAllSitesIDOs //, update, delete, etc. ...
}

export default IdoTrackerService;