import React from "react";
import {
  Box,
  List,
  ListItemButton,
  ListItem,
  ListItemText,
  useTheme,
  ListItemAvatar,
  Avatar,
  Switch,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useDrawerContext } from "./drawerContext";
import StorefrontIcon from '@mui/icons-material/Storefront';
import { Link, useLocation } from "react-router-dom";
import ModeNightRoundedIcon from "@mui/icons-material/ModeNightRounded";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import useAppBarHeight from "../hooks/useAppBarHeight";
import { leftSideWidth } from "../constant/layout";
import { PANCAKESWAPBUY } from "../../constant/addresses";

export function LeftSide({ mode, setMode }) {
  const { isOpened, MENU_LIST } = useDrawerContext();
  const theme = useTheme();
  const location = useLocation();

  const appBarHight = useAppBarHeight();

  const { openWidth, closeWidth } = { ...leftSideWidth };
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return isSmallScreen ? (
    <React.Fragment></React.Fragment>
  ) : (
    <Box
      pt={2}
      sx={{
        width: isOpened ? openWidth : closeWidth,
        height: "100%",
        position: "fixed",
        zIndex: theme.zIndex.appBar - 2,
        paddingTop: `calc(${appBarHight + "px"} + 8px)`,
      }}
      bgcolor={"background.default"}
      borderRight={1}
    >
      <Box>
        <List>
          {MENU_LIST.map((e, i) => (
            <ListItem
              disablePadding={true}
              sx={{
                backgroundColor:
                  e.path === location.pathname
                    ? theme.palette.action.selected
                    : "",
              }}
              key={i}
            >
              <ListItemButton
                disablePadding={true}
                component={Link}
                to={e.path}
              >
                <ListItemAvatar>
                  <Avatar>{e.icon}</Avatar>
                </ListItemAvatar>

                {isOpened ? (
                  <ListItemText
                    primary={
                      <Typography
                        variant="body2"
                        sx={{
                          color: theme.palette.subColor,
                        }}
                      >
                        {e.name}
                      </Typography>
                    }
                    secondary={
                      <Typography
                        variant="body2"
                        color={
                          theme.palette.subColor.light
                        }
                        sx={{ fontSize: "12px" }}
                      >
                        {e.explain}
                      </Typography>
                    }
                  />
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </ListItemButton>
            </ListItem>
          ))}
          <ListItem disablePadding={true} onClick={(e) => window.open(PANCAKESWAPBUY, "_blank")}>
            <ListItemButton >
              <ListItemAvatar
                
              >
                <Avatar>
                <StorefrontIcon></StorefrontIcon>
                </Avatar>
              </ListItemAvatar>
              {isOpened ? (
                  <ListItemText
                    primary={
                      <Typography
                        variant="body2"
                        sx={{
                          color: theme.palette.subColor,
                        }}
                      >
                        Buy
                      </Typography>
                    }
                    secondary={
                      <Typography
                        variant="body2"
                        color={
                          theme.palette.subColor.light
                        }
                        sx={{ fontSize: "12px" }}
                      >
                        PancakeSwap
                      </Typography>
                    }
                  />
                ) : (
                  <React.Fragment></React.Fragment>
                )}
            </ListItemButton>
          </ListItem>

          {isOpened ? (
            <ListItem disablePadding={true}>
              <ListItemButton>
                <ListItemAvatar
                  onClick={(e) => setMode(mode === "light" ? "dark" : "light")}
                >
                  <Avatar>
                    {mode === "light" ? (
                      <LightModeRoundedIcon />
                    ) : (
                      <ModeNightRoundedIcon />
                    )}
                  </Avatar>
                 
                </ListItemAvatar>

                <Switch
                  onChange={(e) => setMode(mode === "light" ? "dark" : "light")}
                />
              </ListItemButton>
            </ListItem>
          ) : (
            <ListItem disablePadding={true}>
              <ListItemButton>
                <ListItemAvatar
                  onClick={(e) => setMode(mode === "light" ? "dark" : "light")}
                >
                  <Avatar>
                    {mode === "light" ? (
                      <LightModeRoundedIcon />
                    ) : (
                      <ModeNightRoundedIcon />
                    )}
                  </Avatar>
                </ListItemAvatar>
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Box>
    </Box>
  );
  //   return <React.Fragment>
  //             {isOpened==true ? <Box >Left Side</Box> : <React.Fragment></React.Fragment>}
  //   </React.Fragment> ;
}
