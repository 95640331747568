import React from "react";

import TableContainer from "@mui/material/TableContainer";
import { GridContainer } from "../grid/GridContainer";

export function TableContainerBase({ children }) {
  return (
    <GridContainer>
      <TableContainer  sx={{ width: "100%" }}>
        {children}
      </TableContainer>
    </GridContainer>
  );
}
