import React from "react";
import { Box, Grid, TableBody } from "@mui/material";

import { GridItem } from "../grid/GridItem";
import { GridContainer } from "../grid/GridContainer";
import { TableBase } from "../tables/TableBase";

import { TableHeadSticky } from "../tables/TableHeadSticky";
import { useTheme } from "@emotion/react";
import { Link } from "react-router-dom";
import { IdoListTableRow } from "./IdoListTableRow";

export const IdoList = ({ idos, website, sliceLength = 4, seeMore = true }) => {
  const theme = useTheme();
  return (
    <GridContainer>
      <Grid item xs={12}>
        <GridItem>
          <TableBase tableLayout="fixed"
            head={
              <TableHeadSticky
                headList={[
                  { title: "Name", exp: "IDO Name" },
                  { title: "Price(USDT)", exp: "Current Price" },
                  { title: "IDO Price", exp: "Token Sale Price" },
                  { title: "IDO Date", exp: "Date of Token Sale" },
                  {
                    title: "Participated",
                    exp: "Total Count User Participated",
                  },
                  { title: "Fund", exp: "Total Fund Participated" },
                  { title: "Token Address", exp: "Token Address" },
                  {
                    title: "P. Address",
                    exp: "Participant Contract Address for IDO",
                  },
                  { title: "Links", exp: "All Links about IDO" },
                ]}
              />
            }
          >
            <TableBody>
              {idos.slice(0, sliceLength).map((data, i) => {
                return (
                  <IdoListTableRow
                    i={i}
                    data={data}
                    website={website}
                  ></IdoListTableRow>
                );
              })}
            </TableBody>
          </TableBase>
        </GridItem>
        {seeMore ? (
          <Box
            justifyContent="center"
            alignItems="center"
            sx={{ padding: "4px" }}
          >
            <Link to={"/websites/" + website} style={{color:theme.palette.routerLink.color}}>See more</Link>
          </Box>
        ) : (
          <React.Fragment></React.Fragment>
        )}
      </Grid>
    </GridContainer>
  );
};
