import React from "react";
import { CoinsAll } from "../../panels/ido/coinsAll";
import { Stack } from "@mui/material";
import { Helmet } from "react-helmet-async";

export function Coins() {
  return (

    <React.Fragment>
    <Helmet>
        <title>ALL IDOS</title>
        <meta name='description' content='coins published on BSCPAD, ETHPAD, POLYPAD, METAVPAD and All BLuezilla web sites' />
      </Helmet>
      <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={0.5}
      px={1}
    >
      <CoinsAll></CoinsAll>
     
    </Stack>
    </React.Fragment>
    
  );
}
