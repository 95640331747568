import React, { useState } from "react";

import {
  Avatar,
  Box,
  Divider,
  Grid,
  TableBody,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { TableBase } from "../../components/tables/TableBase";
import { TableHeadSticky } from "../../components/tables/TableHeadSticky";

import { getIdoIconAddress } from "../../../api/constant";
import { TokenAddressWithLinks } from "../../components/blockchain/TokenAddressWithLinks";

import { IdoTrackerListItemAllocationRow } from "./idoTrackerListItemAllocationRow";
import { web3Objecs } from "../../../utils/solidtyInterect";
import { tokenAbi } from "../../../constant/abi";
import { useTheme } from "@emotion/react";
import { GridItem } from "../../components/grid/GridItem";
import { Price } from "../../components/blockchain/Price";

const setDecimalToken = async function(tokenNetwork, tokenAddress, setDecimal) {
  const web3 = web3Objecs[tokenNetwork];

  const token = new web3.eth.Contract(tokenAbi, tokenAddress);
  const tokenDecimals = await token.methods.decimals().call();
  setDecimal(parseInt(tokenDecimals));
};
export const IdoTrackerListItem = function({ event, setRefreshNeeded }) {
  const [maxClosedIdo, setMaxClosedIdo] = useState(0);
  const [decimal, setDecimal] = useState(18);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  let partInfo = event.participationInfo;
  setDecimalToken(
    event.address.network,
    event.address.tokenAddress,
    setDecimal
  );
  return (
    <GridItem>
      <Grid container spacing={isSmallScreen ? 0 : 2} rowSpacing={2}>
        <Grid item xs={4} sm={4} md={4} lg={3}>
          <Grid container rowSpacing={1}>
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Avatar
                  variant="square"
                  sx={{
                    width: isSmallScreen ? "80px" : "150px",
                    height: isSmallScreen ? "80px" : "150px",
                  }}
                  src={getIdoIconAddress(event.idoLogo)}
                ></Avatar>
              </Box>
            </Grid>
            {/* <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <InfoLinks
                  justifyContent="center"
                  info={[
                    {
                      tooltip: "website",
                      Icon: (
                        <LanguageIcon
                          onClick={() => window.open(event.website, "_blank")}
                        ></LanguageIcon>
                      ),
                    },
                    {
                      tooltip: "twitter",
                      Icon: (
                        <TwitterIcon
                          onClick={() => window.open(event.twitter, "_blank")}
                        ></TwitterIcon>
                      ),
                    },
                    {
                      tooltip: "telegram",
                      Icon: (
                        <TelegramIcon
                          onClick={() => window.open(event.telegram, "_blank")}
                        ></TelegramIcon>
                      ),
                    },
                  ]}
                  max={3}
                />
              </Box>
            </Grid> */}
          </Grid>
        </Grid>

        <Grid item xs={8} sm={8} md={8} lg={9}>
          <Grid container rowSpacing={isSmallScreen ? 0 : 1}>
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Typography variant="h6" component="div">
                  {event.idoName}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Typography variant="subtitle1" component="div">
                  Published on {event.site}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Typography variant="caption">
                  1 {partInfo.symbol} ={" "}
                  {partInfo.rate ? partInfo.rate.toString().slice(0, 6) : ""}{" "}
                  {event.rules.tokenPair1Name}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider orientation="horizontal" flexItem />
        </Grid>
        <Grid item xs={12}>
          <Grid container rowSpacing={2}>
            <Grid item xs={5}>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
              >
                Price:
                <Price
                  tokenAddress={event.address.tokenAddress}
                  tokenNetwork={event.address.network}
                ></Price>
              </Box>
            </Grid>
            <Grid item xs={7}>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <TokenAddressWithLinks
                  showMore={false}
                  isMetamask={true}
                  isScanSite={true}
                  addresses={[event.address]}
                ></TokenAddressWithLinks>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider orientation="horizontal" flexItem />
        </Grid>

        <Grid item xs={12}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <TableBase
                minWidth="800px"
                head={
                  <TableHeadSticky
                    headList={[
                      { title: "No.", exp: "No" },
                      { title: "Allocation", exp: "Allocation" },
                      { title: "Percentage", exp: "Percentage" },
                      { title: "Date", exp: "Date of Token PArticipation" },
                      {
                        title: "Claimed",
                        exp: "Claimed Amount",
                      },
                      {
                        title: "Status",
                        exp: "Status",
                      },
                      { title: "Action", exp: "Action" },
                    ]}
                  />
                }
              >
                <TableBody>
                  {event.infoAllocation.map((data, index) => {
                    return (
                      <IdoTrackerListItemAllocationRow
                        data={data}
                        maxClosedIdo={maxClosedIdo}
                        setMaxClosedIdo={setMaxClosedIdo}
                        idoContractAddress={event.idoContract}
                        index={index}
                        decimal={decimal}
                        setRefreshNeeded={setRefreshNeeded}
                      />
                    );
                  })}
                </TableBody>
              </TableBase>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </GridItem>
  );
};
