import { Paper } from "@mui/material";

import styled from "@emotion/styled";
export const GridItem = styled(Paper)(({ theme, ...props }) => ({

  ...theme.typography.body2,
  padding: props.padding ? props.padding : theme.spacing(1),
  textAlign: "center",
  backgroundImage: props.backgroundImage,
  borderRadius: props.borderRadius ? props.borderRadius :theme.spacing(1),
  backgroundPosition: "center",
  backgroundSize: "fill",
  backgroundRepeat: "no-repeat",
  color: theme.palette.text.secondary,
}));
