import React from "react";
import { Box, Stack, Tooltip } from "@mui/material";

import useCopyClipboard from "../../hooks/useCopyClipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import { useStylesOverflowDot } from "../css/Utility";
import { OpenerListMenu } from "../openerMenu/OpenerListMenu";

export function AddressCopy({ address, ...props }) {
  const classes = useStylesOverflowDot();
  const [value, copy] = useCopyClipboard();

  function onClick(event) {
    copy(address);
  }

  return (
    <Box align="left" px={"8px"}>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={0.2}
      >
        <Box className={classes.overflow}>{address}</Box>
        {value !== address ? (
          <Tooltip title="Copy">
            <ContentCopyIcon
              onClick={onClick}
              fontSize="small"
            ></ContentCopyIcon>
          </Tooltip>
        ) : (
          <Tooltip title="Copied">
            <LibraryAddCheckIcon fontSize="small"></LibraryAddCheckIcon>
          </Tooltip>
        )}

        {props.children}
        <OpenerListMenu
          list={[
            <Box align="left" px={"8px"}>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={0.2}
              >
                <Box className={classes.overflow}>{address}</Box>
                {value !== address ? (
                  <Tooltip title="Copy">
                    <ContentCopyIcon
                      onClick={onClick}
                      fontSize="small"
                    ></ContentCopyIcon>
                  </Tooltip>
                ) : (
                  <Tooltip title="Copied">
                    <LibraryAddCheckIcon fontSize="small"></LibraryAddCheckIcon>
                  </Tooltip>
                )}

                {props.children}
              </Stack>
            </Box>,
          ]}
        ></OpenerListMenu>
      </Stack>
    </Box>
  );
}
