import {  createSlice } from "@reduxjs/toolkit";
import { groupBy } from "../../../../utils/utils";
const initialState = {
  idos: [],
  idosGrouped:{}

};


const idosGroupBy = function(webSitesIDOs) {
  let retVal = [];
  webSitesIDOs.forEach((element) => {
    let data = {};
    element.idos.forEach((token) => {
      data.siteName = element.name;
      data.tokenName = token.info.name.split("(")[0].replace(/\s/g, "");
      data.tokenAddress = token.address;
      data.network = token.network;
      data.totalCountUserParticipated =
        token.participationInfo.totalCountUserParticipated;
      data.totalFundParticipated =
        token.participationInfo.totalFundParticipated;
      data.info = token.info;
      data.when = token.participationInfo.when;
      retVal.push(data);
      data = {};
    });
  });
  retVal = groupBy(retVal, "tokenName");
  for (const key in retVal) {
    if (retVal.hasOwnProperty(key)) {
      retVal[key] = retVal[key].reduce(
        (result, item) => {
          let ret = {
            ...result,
            totalCountUserParticipated:
              result.totalCountUserParticipated +
              Number(item.totalCountUserParticipated),
            totalFundParticipated:
              result.totalFundParticipated + Number(item.totalFundParticipated),
            info: item.info,
            when: item.when,
          };
          if (
            result.sites.length === 0 ||
            !result.sites.some(
              (entry) =>
                entry.siteName === item.siteName &&
                entry.network === item.network
            )
          ) {
            ret.sites.push({
              siteName: item.siteName,
              network: item.network,
              tokenAddress: item.tokenAddress,
            });
          }

          return ret;
        },
        {
          totalCountUserParticipated: 0,
          totalFundParticipated: 0,
          sites: [],
        }
      );
    }
  }
  return retVal ;
};

const setIdosAll = function(state, allIdos) {
  let idos = [...state.idos];

  allIdos.payload.forEach((action)=>{
    if (idos.some((ido) => ido.name === action.name) !== true) {
      idos.push({ name: action.name, idos: action.idos });
    }
  })
 
  let retVAl = {
    ...state,
    idos: idos,
  };
  
  return retVAl;
};

const setIdosGroupByAll = function(state,action) {
  let idos = [...state.idos];
  let retVAl = {
    ...state,
    idosGrouped:idosGroupBy(idos) 
  };
  
  return retVAl;
};

const reducers = {
  setIdos: setIdosAll,
  setIdosGroupBy:setIdosGroupByAll
};

export const idosSlice = createSlice({
  name: "idos",
  initialState,
  reducers: reducers,
});

// Action creators are generated for each case reducer function
export const { setIdos,setIdosGroupBy } = idosSlice.actions; //action creator

export default idosSlice.reducer;
