import Web3 from "web3";
import { pancakeSwapAbi, tokenAbi } from "../../constant/abi";
import {
  BNB_BSC20,
  ETH_ETH,
  pancakeSwapContract,
  POLYGON_MATIC,
  quickSwapContract,
  uniSwapContract,
  USDT_BSC20,
  USDT_ETH,
  USDC_POLYGON,
} from "../../constant/addresses";
import { toFixedPrice } from "../utils";
import { injected } from "../../ui/components/blockchain/InjectedConnector";

export const getChainId = {
  BSC: "56",
  ETH: "1",
  VELAS: "106",
  POLYGON: "137",
};
export const getChainString = {
  56: "BSC",
  1: "ETH",
  106: "VELAS",
  137: "POLYGON",
};

export const CHAIN_INFO = {
  ETH: {
    explorer: "https://etherscan.io/",
    label: "Ethereum",
    nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
    rpcUrl: ["https://ethereum.publicnode.com/"],
  },
  POLYGON: {
    explorer: "https://polygonscan.com/",
    label: "Polygon",
    nativeCurrency: { name: "Polygon Matic", symbol: "MATIC", decimals: 18 },
    rpcUrl: ["https://polygon-rpc.com"],
  },
  BSC: {
    explorer: "https://bscscan.com",
    label: "Binance Smart Chain",
    nativeCurrency: { name: "BNB", symbol: "BNB", decimals: 18 },
    rpcUrl: ["https://bsc-dataseed.binance.org/"],
  },
  VELAS: {
    explorer: "https://evmexplorer.velas.com/rpc",
    label: "Velas Network",
    nativeCurrency: { name: "VLX", symbol: "VLX", decimals: 18 },
    rpcUrl: ["https://evmexplorer.velas.com/rpc"],
  },
};

// export const dexToolChainID = {
//   BSC: "bnb",
//   ETH: "ether",
//   VELAS: "velas",
//   POLYGON: "polygon",
// };

export const coinBrainNetwroks = {
  BSC: "bnb",
  ETH: "eth",
  VELAS: "bnb", // velas
  POLYGON: "poly",
};

export const getCoinBrainTradeWidgetUrl = (network, tokenAddress, mode) => {
  return (
    "https://coinbrain.com/embed/" +
    coinBrainNetwroks[network] +
    "-" +
    tokenAddress +
    "?theme=" +
    mode +
    "&padding=16&chart=1&trades=1&ratio=0.4"
  );
};

export const getCoinBrainCoinTickerUrl = (network, tokenAddress, mode) => {
  return (
    "https://coinbrain.com/coins/" +
    coinBrainNetwroks[network] +
    "-" +
    tokenAddress +
    "/ticker?theme=" +
    mode +
    "&padding=16&type=medium&currency=USD&blocks=totalSupply%2CcirculatingSupply%2Cholders"
  );
};

export const web3Objecs = {
  BSC: new Web3("https://bsc-dataseed1.binance.org"),
  BSCTEST: new Web3("https://bsc-dataseed1.binance.org"),
  //BSCTEST: new Web3("https://data-seed-prebsc-1-s1.binance.org:8545/"),
  ETH: new Web3("https://ethereum.publicnode.com"),
  VELAS: new Web3("https://mainnet.velas.com/rpc"),
  POLYGON: new Web3("https://polygon-rpc.com"),
};

const swapContract = {
  BSC: pancakeSwapContract,
  ETH: uniSwapContract,
  POLYGON: quickSwapContract,
};

const usdtAdress = {
  BSC: USDT_BSC20,
  ETH: USDT_ETH,
  POLYGON: USDC_POLYGON,
};
const mainPairAdress = {
  BSC: BNB_BSC20,
  ETH: ETH_ETH,
  POLYGON: POLYGON_MATIC,
};

function strtodec(amount, dec) {
  var stringf = "";
  for (var i = 0; i < dec; i++) {
    stringf = stringf + "0";
  }
  return amount + stringf;
}
export async function calcUsdtPrice(tokenAddress, tokenNetwork) {
  try {
    const USDTokenAddress = usdtAdress[tokenNetwork]; //USDT
    const BNBTokenAddress = mainPairAdress[tokenNetwork]; //USDT
    const tokenUSDTSellAmount = "100";
    const web3 = web3Objecs[tokenNetwork];
    const tokenUSDT = new web3.eth.Contract(tokenAbi, USDTokenAddress);
    const tokenUSDTDecimal = await tokenUSDT.methods.decimals().call();

    const token = new web3.eth.Contract(tokenAbi, tokenAddress);
    const tokenDecimals = await token.methods.decimals().call();

    const tokensUSDTToSell = strtodec(tokenUSDTSellAmount, tokenUSDTDecimal); //web3.utils.toWei("1", "ether");
    let amountOut;
    let amountOutUSDTBNBToken;
    //route to usdt -> token
    try {
      let router = new web3.eth.Contract(
        pancakeSwapAbi,
        swapContract[tokenNetwork]
      );

      try {
        amountOut = await router.methods
          .getAmountsOut(tokensUSDTToSell, [USDTokenAddress, tokenAddress])
          .call();
      } catch (error) {
        amountOut = [tokensUSDTToSell, 0];
      }
      // route to usdt -> bnb -> token
      try {
        amountOutUSDTBNBToken = await router.methods
          .getAmountsOut(tokensUSDTToSell, [
            USDTokenAddress,
            BNBTokenAddress,
            tokenAddress,
          ])
          .call();
      } catch (error) {
        amountOutUSDTBNBToken = [tokensUSDTToSell, 0, 0];
      }
    } catch (error) {
      amountOut = [tokensUSDTToSell, 0];
      amountOutUSDTBNBToken = [tokensUSDTToSell, 0, 0];
    }

    if (Number(amountOutUSDTBNBToken[2]) === 0 && Number(amountOut[1]) === 0) {
      return 0;
    }

    const amount1 = Number(amountOut[1]) / 10 ** tokenDecimals;
    const amount2 = Number(amountOutUSDTBNBToken[2]) / 10 ** tokenDecimals;
    let retVal =
      amount2 > amount1
        ? Number(tokenUSDTSellAmount) / amount2
        : Number(tokenUSDTSellAmount) / amount1;

    return toFixedPrice(retVal);
  } catch (error) {
    console.log("error", error);
    return 0;
  }
}

export const switchNetwork = async (network, activate, library, active) => {
  try {
    if (!active) {
      await activate(injected);
    }

    await library.currentProvider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: Web3.utils.toHex(getChainId[network]) }],
    });
  } catch (switchError) {
    // 4902 error code indicates the chain is missing on the wallet
    if (switchError.code === 4902) {
      try {
        await library.currentProvider.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: Web3.utils.toHex(getChainId[network]),
              rpcUrls: CHAIN_INFO[network].rpcUrl,
              chainName: CHAIN_INFO[network].label,
              nativeCurrency: {
                name: CHAIN_INFO[network].nativeCurrency.name,
                decimals: CHAIN_INFO[network].nativeCurrency.decimals,
                symbol: CHAIN_INFO[network].nativeCurrency.symbol,
              },
              blockExplorerUrls: [CHAIN_INFO[network].explorer],
            },
          ],
        });
      } catch (error) {
        console.error(error);
      }
    }
  }
};

export const addToMetamsk = async function(address, network) {
  const web3 = web3Objecs[network];
  const tokenRouter = new web3.eth.Contract(tokenAbi, address);
  const tokenDecimals = await tokenRouter.methods.decimals().call();
  const tokensymbol = await tokenRouter.methods.symbol().call();

  try {
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    const wasAdded = await window.ethereum.request({
      method: "wallet_watchAsset",
      chainId: getChainId[network],
      params: {
        type: "ERC20", // Initially only supports ERC20, but eventually more!
        options: {
          address: address, // The address that the token is at.
          symbol: tokensymbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals: tokenDecimals, // The number of decimals in the token
        },
      },
    });

    if (wasAdded) {
      console.log("Thanks for your interest!");
    } else {
      console.log("Your loss!");
    }
  } catch (error) {
    console.log(error);
  }
};
