import React from "react";
import {
  Avatar,
  AvatarGroup,
  Tooltip,

} from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";


export function InfoLinks({
  info = [
    {  Icon: <LanguageIcon></LanguageIcon> ,tooltip:"website"},
    {  Icon: <TwitterIcon></TwitterIcon>  ,tooltip:"twitter" },
    {  Icon: <TelegramIcon></TelegramIcon>  ,tooltip:"telegram" },
  ],
  max = 3,justifyContent="flex-end"
}) {
  return (
    <AvatarGroup max={max} sx={{ justifyContent:justifyContent }}>
      {info.map((data) => {
        return (
          <Tooltip title={data.tooltip} sx={{ marginLeft: "-8px" }} >
            <Avatar sx={{ width: 24, height: 24 }} >
              {data.Icon}
            </Avatar>
          </Tooltip>
        );
      })}

     
    </AvatarGroup>
  );
}
