import React, { useEffect, useState } from "react";


import {TabPanelExt} from "../../components/tabs/TabPanel";
import { HomeWebSiteSelectedTokensList } from "./homeWebSiteSelectedTokenList";
import { Loading } from "../../components/loading/Loading";
import { API } from "../../../api/constant";

export const HomeWebSitesTabPanel = ({ selectedPad,setSelectedPad ,webSites , idos }) => {


  const [tabs, setTabs] = useState([]);
  const [panels, setPanels] = useState([]);
  useEffect(()=>{


      let tabsR = [] ;
      let tabPanels = [] ;

      webSites.forEach((element ,i)=> {
        tabsR.push({image:API.imageUrl  +"/" +element.siteInfo.siteImage2 , name:element.name}) ; 
       
        if(idos.filter(ido => ido.name  === element.name ).length>0){
          
          tabPanels.push(<HomeWebSiteSelectedTokensList 
            selectedPad={selectedPad} idos={idos.filter(ido => ido.name  === element.name )[0].idos} website={webSites[i].name} 
           ></HomeWebSiteSelectedTokensList>);
        }
         

        
      });

      setTabs(tabsR );
      setPanels(tabPanels) ;




  },[webSites,idos]) ; 


  return (panels.length >0 && tabs.length > 0)   ? 
     (
      <TabPanelExt 
      selectedPad={selectedPad}
      setSelectedPad={setSelectedPad}
      tabs={tabs}
      tabPanels= {panels}
      moreLink="/websites" 
    ></TabPanelExt>
    ) : (
    <Loading></Loading>
    )

       
  ;
};
