import React from "react";
import { Box, SpeedDial, SpeedDialAction, SpeedDialIcon, useMediaQuery } from "@mui/material";
import { useDrawerContext } from "./drawerContext";
import { leftSideWidth } from "../constant/layout";
import useAppBarHeight from "../hooks/useAppBarHeight";
import { useTheme } from "@emotion/react";
import { MENU_LIST } from "../constant/routes";
import ModeNightRoundedIcon from "@mui/icons-material/ModeNightRounded";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import { Menu as MenuIcon } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";
import { PANCAKESWAPBUY } from "../../constant/addresses";
import StorefrontIcon from '@mui/icons-material/Storefront';
export function MainSide({ mode,setMode,children }) {
 
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {openWidth,closeWidth} = {...leftSideWidth}
  const appBarHight = useAppBarHeight() ;
  let navigate = useNavigate();
  const { isOpened, toggleIsOpened } = useDrawerContext();
  const handleOpen = () => toggleIsOpened(true);
  const handleClose = () =>{


    toggleIsOpened(false);
  } 
  const handleMenuClick = (path)=>{

    navigate(path) ;
    handleClose();

  }
  return (
    <Box  bgcolor={"background.default"}  
    
    
      pr={isSmallScreen? 0 : 1}
      sx={(theme) => ({
        pl: isOpened ? `calc(${isSmallScreen ? "0px" :openWidth} + 0px)`: `calc(${isSmallScreen ? "0px" :closeWidth} + 0px)`,
        pt:`calc(${appBarHight+"px"} + 16px)` , 
        pb:"20px"

         // [theme.breakpoints.up("lg")]: {
        //   pl: isOpened ? "50px" : "50px",
        // },
      })}
    >
      {children}
      {isSmallScreen ? <SpeedDial
        ariaLabel="Menu"
        sx={{ position: 'fixed', bottom: 16, right: 16  ,}}
        icon={<SpeedDialIcon icon={<MenuIcon />} openIcon={<CloseIcon></CloseIcon>} /> }
        onClose={handleClose}
        onOpen={handleOpen}
        open={isOpened}
        FabProps={{color: theme.fab.color}
          
        }
        
        
      >
        {MENU_LIST.map((action, i) => (
          <SpeedDialAction
            key={i}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={()=>handleMenuClick(action.path)}
          />
        ))}
        <SpeedDialAction
            icon={<StorefrontIcon />}
            
          
          
            tooltipTitle={"Buy"}
            tooltipOpen
            onClick={()=>{window.open(PANCAKESWAPBUY, "_blank")}}
          />

        <SpeedDialAction
            icon={mode === "light" ? (
              <LightModeRoundedIcon />
            ) : (
              <ModeNightRoundedIcon />
            )}
            tooltipTitle={"Mode"}
            tooltipOpen
            onClick={()=>{setMode(mode === "light" ? "dark" : "light"); handleClose();}}
          />
      </SpeedDial>:<React.Fragment></React.Fragment>}
    </Box>
  );
}
