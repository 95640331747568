export const API = {
  url: "https://kcxxouixrh.execute-api.us-east-1.amazonaws.com/prod",
  imageUrl: "https://ifo-tracker-db-bucket.s3.amazonaws.com",
  staticIdos: "/static/idos/",
  staticSites: "/static/sites/",
  add: "/add",
  sites: "/api/site",
  siteIDOs: "/api/site/idos",
  siteIDOsAll: "/api/site/idos/idosAll",
};





const ScanLinks = {
  ETH: {
    scan: {
      token: "https://etherscan.com/token/",
      address: "https://etherscan.com/address/",
    },
  },
  BSC: {
    scan: {
      token: "https://bscscan.com/token/",
      address: "https://bscscan.com/address/",
    },
  },
  VELAS: {
    scan: {
      token: " https://explorer.velas.com/token/",
      address: " https://explorer.velas.com/address/",
    },
  },
  POLYGON: {
    scan: {
      token: "https://polygonscan.com/token/",
      address: "https://polygonscan.com/address/",
    },
  },
};

const ScanImages = {
  ETH: "img/etherscan.svg",
  BSC: "img/bscscan.svg",
  VELAS: "img/velasScan.png",
  POLYGON: "img/polygonScan.png",
};

const ScanTitles = {
  ETH: "See on EtherScan",
  BSC: "See on BscScan",
  VELAS: "See on Velas Explorer",
  POLYGON: "See on PolygonScan",
};

export const getScanLink = function(network) {
  return ScanLinks[network];
};

export const getScanImage = function(network) {
  return ScanImages[network];
};

export const getScanTitle = function(network) {
  return ScanTitles[network];
};

export const getSiteIconAddressByName = function(siteName) {
  return API.imageUrl + API.staticSites + siteName;
};

export const getIdoIconAddress = function(imgUrl) {
  return API.imageUrl + "/" + encodeURIComponent(imgUrl);
};
export const getSiteIconAddressByURL = function(imgUrl) {
  return API.imageUrl + "/" + encodeURIComponent(imgUrl);
};






