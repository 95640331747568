import React from "react";

import { Box, Grid, Typography, useMediaQuery } from "@mui/material";

import { GridItem } from "../../components/grid/GridItem";

import { IdoTrackerListItem } from "./idoTrackerListItem";
import { useWeb3React } from "@web3-react/core";
import { MetamaskConnect } from "../../components/blockchain/MetamaskConnect";
import { useTheme } from "@emotion/react";

export const IdoTrackerList = function({ events, caseIdo ,setRefreshNeeded}) {
  let values = events.filter((event) => {
    return (
      event.infoAllocation &&
      event.infoAllocation.length > 0 &&
      (event.infoAllocation.some((i) => i.status === caseIdo) ||
        caseIdo === "ALL")
    );
  });
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { active } = useWeb3React();
  const length = values.length;
  const lengthHalf = Math.ceil(length / 2);
  return (
    <Grid container xs={12} spacing={isSmallScreen ? 0 : 2} rowSpacing={2}>
      {length > 0 ? (
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography variant="caption">
              Total Funded Ido : {length}
            </Typography>
          </Box>
        </Grid>
      ) : (
        <React.Fragment></React.Fragment>
      )}
      {length > 0 ? (
        <React.Fragment>
          <Grid item xs={12} lg={6}>
            <Grid container rowSpacing={2}>
              {values.slice(0, lengthHalf).map((event, index) => {
                return (
                  <Grid item xs={12}>
                    <IdoTrackerListItem event={event} setRefreshNeeded={setRefreshNeeded} />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container rowSpacing={2}>
              {values.slice(lengthHalf).map((event) => {
                return (
                  <Grid item xs={12}>
                    <IdoTrackerListItem event={event} setRefreshNeeded={setRefreshNeeded}/>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </React.Fragment>
      ) : active ? (
        <Grid item xs={12}>
          <GridItem>
            <Typography variant="h6">
              Total Funded IDOs Count : {length}
            </Typography>
          </GridItem>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <GridItem>
          <Box  justifyContent="center" alignItems="center">
              <MetamaskConnect></MetamaskConnect>
            </Box>
          </GridItem>
        </Grid>
      )}
    </Grid>
  );
};
