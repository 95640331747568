import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  Avatar,
  useMediaQuery,
} from "@mui/material";

import { Link } from "react-router-dom";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useTheme } from "@emotion/react";
import { GridItem } from "../../components/grid/GridItem";
import { GridContainer } from "../../components/grid/GridContainer";
import { useWeb3React } from "@web3-react/core";
import { BSC_IDO_TRACKER_CONTRACT } from "../../../constant/addresses";
import { web3Objecs } from "../../../utils/solidtyInterect";
import { idoTrackerAbi } from "../../../constant/idoTrackerAbi";
import { Loading } from "../../components/loading/Loading";
import Web3 from "web3";
const controlISClaimed = async (setClaimed, setLoading, account) => {
  try {
    const web3 = web3Objecs["BSC"];
    const idoTracker = new web3.eth.Contract(
      idoTrackerAbi,
      BSC_IDO_TRACKER_CONTRACT
    );
    const idoTrackerClaimed = await idoTracker.methods
      .isClaimed(account)
      .call();
    setClaimed(idoTrackerClaimed);
  } catch (e) {
    setLoading(false);
  }
};

const claimIdoTrackerAsync = async function(
  library,
  account,
  setClaimed,
  setLoading
) {
  try {

    await library.currentProvider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId:Web3.utils.toHex(56) }],
    });
    const contract = new library.eth.Contract(
      idoTrackerAbi,
      BSC_IDO_TRACKER_CONTRACT
    );
    const claim = await contract.methods.claim().send({ from: account });
    setLoading(false);
    setClaimed(claim);
  } catch (e) {
    setLoading(false);
  }
};

const claimClicked = function(setLoading, setClaimed, account, library) {
  setLoading(true);
  claimIdoTrackerAsync(library, account, setClaimed, setLoading);
};

export function HomeInfo() {
  const theme = useTheme();
  const { active, account, library } = useWeb3React();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [claimed, setClaimed] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (active) {
      controlISClaimed(setClaimed, setLoading, account);
    }
  }, [active, account]);

  return (
    <GridContainer>
      <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
        <GridItem>
          <GridContainer>
            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                direction: "row",
                alignItems: isSmallScreen ? "left" : "center",
                justifyContent: "center",
              }}
            >
              <Box pl={{ xs: 0, sm: 0, md: 1 }}>
                <Avatar
                  sx={{
                    height: isSmallScreen ? "80px" : "150px",
                    width: isSmallScreen ? "80px" : "150px",
                  }}
                >
                  <img
                    alt="main"
                    src="img/main.png"
                    width={"100%"}
                    height={"100%"}
                    object-fit="contain"
                  />
                  <Typography
                    variant={isSmallScreen ? "caption" : "h6"}
                    width={"30px"}
                    align="left"
                    sx={{
                      position: "relative",
                      top: "0px",
                      textOrientation: "sideways",
                      writingMode: "vertical-rl",
                    }}
                  >
                    App
                  </Typography>
                </Avatar>
              </Box>
            </Grid>
            <Grid item xs={8} sx={{ display: "flex", alignItems: isSmallScreen ? "left" : "center" }}>
              <Stack direction="column" spacing={1} alignItems={isSmallScreen ? "left" : "center"}>
                <Typography
                  variant={isSmallScreen ? "caption" : "subtitle1"}
                  align="center"
                >
                  Track <strong>IDOs</strong> you've Joined Easily
                </Typography>
                <Box align={isSmallScreen ? "left" : "center"}>
                  <Button
                    variant="contained"
                    endIcon={<PlayArrowIcon />}
                    component={Link}
                    to={"/app"}
                    sx={{ backgroundColor: theme.palette.subColor.light }}
                  >
                    Run APP
                  </Button>
                </Box>
              </Stack>
            </Grid>
          </GridContainer>
        </GridItem>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
        <GridItem>
          <GridContainer>
            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                direction: "row",
                alignItems: "flex-start",
                justifyContent: "center",
              }}
            >
              <Box pl={{ xs: 0, sm: 0, md: 1 }}>
                <Avatar
                  sx={{
                    height: isSmallScreen ? "80px" : "150px",
                    width: isSmallScreen ? "80px" : "150px",
                  }}
                >
                  <img
                    alt="airdrop"
                    src="img/airdropc.png"
                    width={"100%"}
                    height={"100%"}
                    object-fit="contain"
                  />
                  <Typography
                    variant={isSmallScreen ? "caption" : "h6"}
                    align="center"
                    sx={{
                      position: "relative",
                      top: "0px",
                      textOrientation: "sideways",
                      writingMode: "vertical-rl",
                    }}
                  >
                    Airdrop
                  </Typography>
                </Avatar>
              </Box>
            </Grid>
            <Grid item xs={8} sx={{ display: "flex", alignItems: isSmallScreen ? "left" : "center" }}>
              <Stack direction="column" spacing={1} alignItems={isSmallScreen ? "left" : "center"}>
                <Typography
                  variant={isSmallScreen ? "caption" : "subtitle1"}
                  align="center"
                >
                  {(!active ? "Connect to " : "") + "Claim Free IDT Token"}
                </Typography>

                <Box align={isSmallScreen ? "left" : "center"}>
                  {claimed ? (
                    <GridItem>
                      <Typography>Already Airdrop Claimed...</Typography>
                    </GridItem>
                  ) : loading ? (
                    <Loading minHeight="0px"></Loading>
                  ) : (
                    <Button
                      disabled={active ? false : true}
                      variant="contained"
                      endIcon={<PlayArrowIcon />}
                      onClick={(event) =>
                        claimClicked(setLoading, setClaimed, account, library)
                      }
                      sx={{ backgroundColor: theme.palette.subColor.light }}
                    >
                      Claim
                    </Button>
                  )}
                </Box>
              </Stack>
            </Grid>
          </GridContainer>
        </GridItem>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
        <GridItem>
          <GridContainer>
            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                direction: "row",
                alignItems: "flex-start",
                justifyContent: "center",
              }}
            >
              <Box pl={{ xs: 0, sm: 0, md: 1 }}>
                <Avatar
                  sx={{
                    height: isSmallScreen ? "80px" : "150px",
                    width: isSmallScreen ? "80px" : "150px",
                  }}
                >
                  <img
                    alt="holders"
                    src="img/holdersc.jpg"
                    width={"100%"}
                    height={"100%"}
                    object-fit="contain"
                  />
                  <Typography
                    variant={isSmallScreen ? "caption" : "h6"}
                    align="center"
                    sx={{
                      position: "relative",
                      top: "0px",
                      textOrientation: "sideways",
                      writingMode: "vertical-rl",
                    }}
                  >
                    Users
                  </Typography>
                </Avatar>
              </Box>
            </Grid>
            <Grid item xs={8} sx={{ display: "flex", alignItems: isSmallScreen ? "left" : "center" }}>
              <Stack direction="column" spacing={1} alignItems={isSmallScreen ? "left" : "center"}>
                <Box>
                  <Typography
                    variant={isSmallScreen ? "caption" : "subtitle1"}
                    align="center"
                    sx={{ position: "relative", top: "0px" }}
                  >
                    People Using IDO Tracker
                  </Typography>
                </Box>
                <Box align={isSmallScreen ? "left" : "center"}>
                  <Button
                    variant="contained"
                    endIcon={<PlayArrowIcon />}
                    sx={{ backgroundColor: theme.palette.subColor.light }}
                    onClick={() =>
                      window.open(
                        "https://bscscan.com/token/" +
                          BSC_IDO_TRACKER_CONTRACT +
                          "#balances",
                        "_blank"
                      )
                    }
                  >
                    BSCScan
                  </Button>
                </Box>
              </Stack>
            </Grid>
          </GridContainer>
        </GridItem>
      </Grid>
    </GridContainer>
  );
}
