import React from "react";
import {
  Grid,
  Avatar,
  Card,
  CardHeader,
  Box,
  Typography,
  Divider,
  CardContent,
  TableBody,
  TableCell,
  useMediaQuery,
} from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";
import { Loading } from "../../components/loading/Loading";
import { useStylesPosition } from "../../components/css/Utility";

import { Link } from "react-router-dom";
import { getSiteIconAddressByURL } from "../../../api/constant";
import { TableBase } from "../../components/tables/TableBase";
import { TableHeadBase } from "../../components/tables/TableHeadBase";
import { InfoLinks } from "../../components/link/InfoLinks";
import { useTheme } from "@emotion/react";

const sumUsers = (siteName, idos) =>
  idos
    .filter((ido) => ido.name === siteName)[0]
    .idos.reduce((accumulator, ido) => {
      return (
        accumulator + parseInt(ido.participationInfo.totalCountUserParticipated)
      );
    }, 0);



export function SitesAllBody({ sites, siteName, searchText, idos }) {
  const classes = useStylesPosition();
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <React.Fragment>
      {sites.length > 0 ? (
        sites
          .filter((e) => e.name.includes(siteName) || siteName === "ALL")
          .filter((e) =>
            e.name.toLowerCase().includes(searchText.toLowerCase())
          )
          .map((element) => {
          
            return (
              <Grid item xs={12} md={12} lg={6} xl={4}>
                <Card>
                  <CardHeader
                    avatar={
                      <Avatar
                        variant="square"
                       
                        sx={{
                          width: isSmallScreen?"80px":"150px", height:isSmallScreen?"80px": "150px",
                          objectFit: "fill",
                        }}
                        src={getSiteIconAddressByURL(
                          element.siteInfo.siteImage2
                        )}
                      ></Avatar>
                    }
                    title={
                      <Typography variant="h6" component="div">
                        {element.name}
                      </Typography>
                    }
                    subheader={
                      <React.Fragment>
                        <Typography variant="body2" color="text.secondary">
                          {element.siteInfo.description}
                        </Typography>
                        <Divider></Divider>
                        <Box>

                        </Box>
                        <Divider></Divider>
                        <Box
                          p={2}
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Link
                            to={
                              "/websites/" +
                              element.name.split("(")[0].replace(/\s/g, "")
                            }
                            style={{color:theme.palette.routerLink.color}}
                          >
                            See more
                          </Link>
                        </Box>
                      </React.Fragment>
                    }
                  ></CardHeader>
                  <Divider></Divider>
                  <Divider></Divider>
                  <CardContent>
                    <TableBase
                      minWidth={"300px"}
                      head={
                        <TableHeadBase
                          headList={[
                            {
                              title: "Total Participant",
                              exp: "Count of Total Participant",
                            },
                            {
                              title: "Total IDO",
                              exp: "Total Count IDOS Published",
                            },
                            {
                              title: "Links",
                              exp: "Social Links",
                            },
                          ]}
                        />
                      }
                    >
                      <TableBody>
                        <TableCell
                          className={`${classes.font}`}
                          sx={{ padding: "8px", alignItems: "center" }}
                        >
                          {idos.length > 0 &&
                          idos.filter((ido) => ido.name === element.name)
                            .length > 0
                            ? sumUsers(element.name, idos)
                            : 0}
                        </TableCell>
                        <TableCell
                          className={`${classes.font}`}
                          sx={{ padding: "8px", alignItems: "center" }}
                        >
                          {idos.length > 0 &&
                          idos.filter((ido) => ido.name === element.name)
                            .length > 0
                            ? idos.filter((ido) => ido.name === element.name)[0]
                                .idos.length
                            : 0}
                        </TableCell>
                        <TableCell
                          className={`${classes.font}`}
                          sx={{ padding: "8px", alignItems: "center" }}
                        >
                          <Box>
                            <InfoLinks
                              max={3}
                              info={[
                                {
                                  tooltip: "website",
                                  Icon: (
                                    <LanguageIcon
                                      onClick={() =>
                                        window.open(
                                          element.siteInfo.website,
                                          "_blank"
                                        )
                                      }
                                    ></LanguageIcon>
                                  ),
                                },
                                {
                                  tooltip: "twitter",
                                  Icon: (
                                    <TwitterIcon
                                      onClick={() =>
                                        window.open(
                                          element.siteInfo.twitter,
                                          "_blank"
                                        )
                                      }
                                    ></TwitterIcon>
                                  ),
                                },
                                {
                                  tooltip: "telegram",
                                  Icon: (
                                    <TelegramIcon
                                      onClick={() =>
                                        window.open(
                                          element.siteInfo.telegram,
                                          "_blank"
                                        )
                                      }
                                    ></TelegramIcon>
                                  ),
                                },
                              ]}
                            />
                          </Box>
                        </TableCell>
                      </TableBody>
                    </TableBase>
                  </CardContent>
                </Card>
              </Grid>
            );
          })
      ) : (
        <Loading></Loading>
      )}
    </React.Fragment>
  );
}
