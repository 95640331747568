import React, { useEffect } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";

import {  getChainString, switchNetwork } from "../../../utils/solidtyInterect";
import { useWeb3React } from "@web3-react/core";
import { useSearchParams } from "react-router-dom";
import { arrayUniqueByKey } from "../../../utils/utils";



export function OptionsApp({
  chain,
  setChain,
  site,
  setSite,
  ido,
  setIdo,
  caseIdo,
  setCaseIdo,
  chainList,
  sites,idos
}) {

  const {
    active,
    library,
    activate,
    chainId
  } = useWeb3React();
 
  useEffect(() => {
    if(active && getChainString[chainId] !== chain ){
      
        setChain( getChainString[chainId]);
        setSite("ALL");
        setIdo("ALL");
        setCaseIdo("ALL");
       
    }
        
  }, [active,chainId]);

  const handleChain = (event) => {

    switchNetwork(event.target.value,activate,library,active) ;
 
  };
  const handleSite = (event) => {
    setSite(event.target.value);
    setIdo("ALL");
    setCaseIdo("ALL")
  };

  const handleIdo = (event) => {
    setIdo(event.target.value);
    setCaseIdo("ALL")
  };

  const handleCase = (event) => {
    setCaseIdo(event.target.value);
  };

 


  
  


  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={4}
      useFlexGap
      flexWrap="wrap"
    >
      <Box>
        {chainList ? (
          <FormControl variant="standard" sx={{ minWidth: 75 }}>
            <InputLabel id="simple-select-label">Chain</InputLabel>

            <Select
              labelId="simple-select-label"
              id="simple-select"
              value={chain}
              label="Chain"
              onChange={handleChain}
            >
              {Array.from(chainList).map((e) => {
                return <MenuItem value={e}>{e}</MenuItem>;
              })}
            </Select>
          </FormControl>
        ) : (
          <React.Fragment></React.Fragment>
        )}
      </Box>
      <Box>
        {sites ? (
          <FormControl variant="standard" sx={{ minWidth: 75 }}>
            <InputLabel id="simple-select-label">Site</InputLabel>

            <Select
              labelId="simple-select-label"
              id="simple-select"
              value={site}
              label="Site"
              onChange={handleSite}
            >
                <MenuItem value="ALL">ALL</MenuItem>
              {sites.filter(s=> {  return s.whichNetworks[0]===chain || chain==="ALL"}).map((e) => {
                
                return <MenuItem value={e.name}>{e.name}</MenuItem>;
              })}
            </Select>
          </FormControl>
        ) : (
          <React.Fragment></React.Fragment>
        )}
      </Box>
      
      <Box>
      {sites && idos && idos.length>0  ? (
          <FormControl variant="standard" sx={{ minWidth: 75 }}>
            <InputLabel id="simple-select-label">Ido</InputLabel>

            <Select
              labelId="simple-select-label"
              id="simple-select"
              value={ido}
              label="IDOs"
              onChange={handleIdo}
            >
                <MenuItem value="ALL">ALL</MenuItem>
              {arrayUniqueByKey(idos.filter((ido) => ido.name === site ||site ==="ALL").map(s=>s.idos.filter((ido)=> ido.network ===chain).map((e) => {
                
                return {name:e.name};
              })).reduce((prev,next)=> prev.concat(next)).sort((a,b)=>a.name < b.name ?-1:0) ,"name").map(e=>  <MenuItem value={e.name}>{e.name}</MenuItem>)}
            </Select>
          </FormControl>
        ) : (
          <React.Fragment></React.Fragment>
        )}
    
      </Box>
      <Box>
        <FormControl variant="standard" sx={{ minWidth: 75 }}>
          <InputLabel id="simple-select-label">Case</InputLabel>
          <Select
            labelId="simple-select-label"
            id="simple-select"
            value={caseIdo}
            label="IDOs"
            onChange={handleCase}
          >
            <MenuItem value={"ALL"}>ALL</MenuItem>
            <MenuItem value={"OPEN"}>OPEN</MenuItem>
            <MenuItem value={"CLOSED"}>CLOSED</MenuItem>
            <MenuItem value={"PENDING"}>PENDING</MenuItem>
          </Select>
        </FormControl>
      </Box>
      
     

   

       
  
     
    </Stack>
  );
}
