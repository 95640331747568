



export const BNB_BSC20 = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'.toLowerCase(); ;//WBNB
export const ETH_ETH = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'.toLowerCase(); ;//Weth
export const POLYGON_MATIC = '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270'.toLowerCase(); ;//matic
export const USDT_BSC20  = "0x55d398326f99059fF775485246999027B3197955".toLowerCase(); // usdt
export const USDT_ETH = "0xdAC17F958D2ee523a2206206994597C13D831ec7".toLowerCase(); // usdt
export const USDC_POLYGON = "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174".toLowerCase(); // usdt

export const FACTROY_ADDRESS = '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73'; // factoryAdress liq yaratan adress  pairları yaratan 

export const pancakeSwapContract = "0x10ED43C718714eb63d5aA57B78B54704E256024E".toLowerCase(); //.toLowerCase(); // router adress fiyat hesaplayan contract
export const uniSwapContract = "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D".toLowerCase(); // router adress fiyat hesaplayan contract
export const quickSwapContract = "0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff"; // router adress fiyat hesaplayan contract



export const BSC_IDO_TRACKER_CONTRACT = "0xD93fdA746C7E8b10E9520652A769F4cF04f4eC82".toLowerCase() ;
export const PANCAKESWAPBUY = "https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency="+BSC_IDO_TRACKER_CONTRACT ;
