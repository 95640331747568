import React from "react";
import {
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";




import {  API } from "../../../api/constant";
import moment from "moment";

import { currencyFormat } from "../../../utils/utils";


import LanguageIcon from "@mui/icons-material/Language";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";

import { TokenAddressWithLinks } from "../blockchain/TokenAddressWithLinks";
import { useStylesStickColumnTable } from "../css/Utility";
import { InfoLinks } from "../link/InfoLinks";
import { Loading } from "../loading/Loading";
import { TableCellPrice } from "../tables/TableCellPrice";
import { Link } from "react-router-dom";

export const IdoListTableRow = ({ i, data, website }) => {
  const classes = useStylesStickColumnTable();
  let partInfo = data.participationInfo ;
  let coinInfo = data.info ;

  return partInfo ? (
    <TableRow >
      <TableCell
        sx={{ padding: "8px" }}
        
        align="left"
        className={`${classes.sticky} ${classes.font} ${classes.overflow}  `}
      ><Link to={"/idos/" + data.name.split("(")[0].replace(/\s/g, "") + "/"+ data.address}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0.5}
        >
          <img alt="Web Site"
            src={
              API.imageUrl +
              API.staticIdos +
              website +
              "/" +
              encodeURIComponent(data.name.replace(/\s/g, ""))
            }
            width={"30px"}
            height={"30px"}
          ></img>
          <Typography variant="caption" className={classes.overflow}>
            {data.name}
          </Typography>
        </Stack>
        </Link>
      </TableCell>

      <TableCellPrice
        sx={{ padding: "8px" }}
        tokenAddress={data.address}
        network={data.network}
        key={i + data.address}
        className={`${classes.font}`}
      ></TableCellPrice>

      <TableCell
        className={`${classes.font}`}
        sx={{ padding: "8px", overflow: "hidden" }}
      >
        <Typography variant="caption" className={classes.overflow}>
          1 {partInfo.symbol} ={" "}
          {partInfo.rate ? partInfo.rate.toString().slice(0, 6) : ""}{" "}
          {data.rules.tokenPair1Name}
        </Typography>
      </TableCell>
      <TableCell className={`${classes.font}`} sx={{ padding: "8px" }}>
        {moment(partInfo.when * 1000 || 0)
          .utc()
          .format("YYYY-MM-DD")}
      </TableCell>
      <TableCell className={`${classes.font}`} sx={{ padding: "8px" }}>
        {partInfo.totalCountUserParticipated}
      </TableCell>
      <TableCell className={`${classes.font}`} sx={{ padding: "8px" }}>
        {partInfo.totalFundParticipated
          ? currencyFormat(
              partInfo.totalFundParticipated.toString().split(".")[0]
            ) +
            " " +
            data.rules.tokenPair0Name
          : ""}
      </TableCell>

      <TableCell sx={{ padding: "8px" }}>
      <TokenAddressWithLinks
                            isMetamask={true}
                            isScanSite={true}
                            addresses={[{tokenAddress:data.address , network:data.network}]}
                          ></TokenAddressWithLinks>
      </TableCell>

      <TableCell sx={{ padding: "8px" }}>
      <TokenAddressWithLinks scanType="address"
                            isMetamask={false}
                            isScanSite={true}
                            addresses={[{tokenAddress:data.contractMethods.address , network:data.network}]}
                          ></TokenAddressWithLinks>
      
      </TableCell>
      <TableCell sx={{ padding: "8px" }}>
        <InfoLinks
        info={[
          { tooltip: "website", Icon: <LanguageIcon onClick={() => window.open(coinInfo.website, "_blank")}></LanguageIcon> },
          { tooltip: "twitter", Icon: <TwitterIcon onClick={() => window.open(coinInfo.twitter, "_blank")}></TwitterIcon> },
          
          { tooltip: "telegram", Icon: <TelegramIcon onClick={() => window.open(coinInfo.telegram, "_blank")}></TelegramIcon> },
          
          ]}
        max={3} />
      </TableCell>
    </TableRow>
  ) : (
    <Loading></Loading>
  );
};
