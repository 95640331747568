import React, { useEffect, useState } from "react";

import { Box, Button, TableCell, TableRow, Typography } from "@mui/material";

import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useTheme } from "@emotion/react";

import { useWeb3React } from "@web3-react/core";
import { idoContract } from "../../../constant/abi";
import { useDispatch } from "react-redux";
import { setGlobalLoading } from "../../../stateManagement/slices/ui/uitls";
import { useStylesStickColumnTable } from "../../components/css/Utility";
import { formatTokenAllocation } from "../../../utils/utils";
export const IdoTrackerListItemAllocationRow = function({
  data,
  idoContractAddress,
  index,maxClosedIdo,setMaxClosedIdo,decimal,setRefreshNeeded
}) {
  const [claimed, setClaimed] = useState( false  );
  const theme = useTheme();
  const { library, account } = useWeb3React();
  const classes = useStylesStickColumnTable();
  const dispatch = useDispatch();
  const claimIdoAsync = async function( ) {
    const contract = new library.eth.Contract(idoContract, idoContractAddress);
    const claim = await contract.methods.claim(index.toString()).send({ from: account });
    
    return claim;
  };
  useEffect(()=>{
     if(index< maxClosedIdo) {
      setClaimed(true) ;
     }
  },[maxClosedIdo])
  const claimIdo = function( ) {
    dispatch(setGlobalLoading({ value: true, message: "Claiming..." }));
    claimIdoAsync( )
      .then((a) => {
        dispatch(setGlobalLoading({ value: false }));
        setClaimed(true);
        setRefreshNeeded(true) ;
        if(index>maxClosedIdo) setMaxClosedIdo(index) ;
      })
      .catch((error) => {
        console.log("claimIdo", error);
        dispatch(setGlobalLoading({ value: false }));
      });
  };

const allocation = data.allocationAmount.split('-') ;

  return (
    <TableRow>
      <TableCell
        sx={{ padding: "8px" }}
        align="left"
        className={`${classes.sticky} ${classes.font} ${classes.overflow}  `}
      >
        <Typography variant="caption" className={classes.overflow}>
          {data.no}
        </Typography>
      </TableCell>

      <TableCell
        className={`${classes.font}`}
        sx={{ padding: "8px", overflow: "hidden" }}
      >
        <Typography variant="caption" className={classes.overflow}>
          {formatTokenAllocation( data.allocationAmount,decimal,4)}
        </Typography>
      </TableCell>
      <TableCell
        className={`${classes.font}`}
        sx={{ padding: "8px", overflow: "hidden" }}
      >
        <Typography variant="caption" className={classes.overflow}>
          {(data.percentage / 100).toFixed(2)}% 
        </Typography>
      </TableCell>
      <TableCell
        className={`${classes.font}`}
        sx={{ padding: "8px", overflow: "hidden" }}
      >
        <Typography variant="caption" className={classes.overflow}>
          {data.timestamp}
        </Typography>
      </TableCell>
      <TableCell
        className={`${classes.font}`}
        sx={{ padding: "8px", overflow: "hidden" }}
      >
        <Typography variant="caption" className={classes.overflow}>
          {claimed ? formatTokenAllocation(data.allocationAmount,decimal,4) : formatTokenAllocation(data.claimedAmount.split('/')[0],decimal,4)  }
        </Typography>
      </TableCell>
      <TableCell
        className={`${classes.font}`}
        sx={{ padding: "8px", overflow: "hidden" }}
      >
        <Typography variant="caption" className={classes.overflow}>
          {(claimed && (allocation.length===1 ||(allocation.length>1 && allocation[0]===allocation[1] )))? "CLOSED" : (claimed && (allocation.length>1 && parseInt(allocation[0])<parseInt(allocation[1]) )) ? "PENDING": data.status}
        </Typography>
      </TableCell>
      <TableCell
        className={`${classes.font}`}
        sx={{ padding: "8px", overflow: "hidden" }}
      >
        <Box align="center">
          <Button
            disabled={data.status !== "OPEN" || claimed}
            variant="contained"
            endIcon={<PlayArrowIcon />}
            sx={{ backgroundColor: theme.palette.subColor.light }}
            onClick={(e) => claimIdo( )}
          >
            {(data.status === "OPEN" && !claimed )
              ? "Claim"
              : (data.status === "CLOSED" || (claimed && (allocation.length ===1 || (allocation.length> 0 && allocation[0]===allocation[1] )) ) ) 
              ? "CLOSED"
              : "PENDING"}
          </Button>
        </Box>
      </TableCell>
    </TableRow>
  );
};
