import React from "react";
import { Box, Icon, Stack, Tooltip } from "@mui/material";

import useCopyClipboard from "../../hooks/useCopyClipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import { useStylesOverflowDot, useStylesSvgIcon } from "../css/Utility";
import { OpenerListMenu } from "../openerMenu/OpenerListMenu";
import { addToMetamsk } from "../../../utils/solidtyInterect";
import { getScanImage, getScanLink, getScanTitle } from "../../../api/constant";
import { groupBy } from "../../../utils/utils";

export function TokenAddressWithLinks({
  isMetamask = false,
  isScanSite = false,
  scanType = "token",
  addresses = [],
  showMore=true , 
  ...props
}) {
  const classes = useStylesOverflowDot();
  const [value, copy] = useCopyClipboard();
  const svg = useStylesSvgIcon();
  function onClick(data) {
    copy(data);
  }

  return (
    <React.Fragment>
      <Box align="left" px={"8px"}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={0.2}
        >
          {addresses.slice(0, 1).map((data) => (
            <React.Fragment>
              <Box className={classes.overflow} sx={{maxWidth:"80px"}}>
                {data.tokenAddress}
              </Box>
              {value !== data.tokenAddress ? (
                <Tooltip title="Copy" >
                  <ContentCopyIcon
                    onClick={(event) => onClick(data.tokenAddress)}
                    fontSize="small"
                  ></ContentCopyIcon>
                </Tooltip>
              ) : (
                <Tooltip title="Copied" >
                  <LibraryAddCheckIcon fontSize="small"></LibraryAddCheckIcon>
                </Tooltip>
              )}

              {isMetamask ? (
                <Tooltip
                  title="Add to Matamask"
                  className={svg.iconRoot}
                  
                >
                  <Icon className={svg.iconRoot}  >
                    <img
                      alt="Metamask"
                      onClick={() =>
                        addToMetamsk(data.tokenAddress, data.network)
                      }
                      className={svg.imageIcon}
                      src={`${process.env.PUBLIC_URL}/img/metamask.svg`}
                    />
                  </Icon>
                </Tooltip>
              ) : (
                <React.Fragment></React.Fragment>
              )}

              {isScanSite ? (
                <Tooltip
                  
                  title={getScanTitle(data.network)}
                  className={svg.iconRoot}
                >
                  <Icon className={svg.iconRoot} >
                    <img
                      alt="Scan"
                      onClick={() =>
                        window.open(
                          getScanLink(data.network).scan[scanType] +
                            data.tokenAddress,
                          "_blank"
                        )
                      }
                      className={svg.imageIcon}
                      src={`${process.env.PUBLIC_URL}/${getScanImage(data.network)}`}
                    />
                  </Icon>
                </Tooltip>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </React.Fragment>
          ))}
          {
            showMore ? <OpenerListMenu
            
            list={Object.entries(groupBy(addresses, "network")).map(
              ([key, token]) => (
                <Stack
                  
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  spacing={1}
                >
                  <Box className={classes.overflow} >
                    {token[0].tokenAddress}
                  </Box>
                  <Box >
                    {value !== token[0].tokenAddress ? (
                      <Tooltip title="Copy" >
                        <ContentCopyIcon
                          onClick={(event) => onClick(token[0].tokenAddress)}
                          fontSize="small"
                        ></ContentCopyIcon>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Copied" >
                        <LibraryAddCheckIcon fontSize="small"></LibraryAddCheckIcon>
                      </Tooltip>
                    )}

                    {isMetamask ? (
                      <Tooltip
                        title="Add to Matamask"
                        className={svg.iconRoot}
                        
                      >
                        <Icon className={svg.iconRoot} >
                          <img 
                            alt="Metamask"
                            onClick={() =>
                              addToMetamsk(
                                token[0].tokenAddress,
                                token[0].network
                              )
                            }
                            className={svg.imageIcon}
                            src={`${process.env.PUBLIC_URL}/img/metamask.svg`}
                          />
                        </Icon>
                      </Tooltip>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}

                    {isScanSite ? (
                      <Tooltip 
                        
                        title={getScanTitle(token[0].network)}
                        className={svg.iconRoot}
                      >
                        <Icon className={svg.iconRoot} >
                          <img
                          
                            alt="Scan"
                            onClick={() =>
                              window.open(
                                getScanLink(token[0].network).scan[scanType] +
                                  token[0].tokenAddress,
                                "_blank"
                              )
                            }
                            className={svg.imageIcon}
                            src={`${process.env.PUBLIC_URL}/${getScanImage(token[0].network)}`}
                          />
                        </Icon>
                      </Tooltip>
                    ) : (
                      < React.Fragment></React.Fragment>
                    )}
                  </Box>
                </Stack>
              )
            )}
          ></OpenerListMenu> : <React.Fragment></React.Fragment>
          }
          
        </Stack>
      </Box>
    </React.Fragment>
  );
}
