import React from "react";

import {
  
  Box,
  Divider,
  Grid,

  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";
import { useTheme } from "@emotion/react";
import { Price } from "../blockchain/Price";
import { API } from "../../../api/constant";
import { InfoLinks } from "../link/InfoLinks";
import { Link } from "react-router-dom";
export const SiteInfo = ({ selectedSite , idos,seeMore=true}) => {
  const theme = useTheme();

  const sumUsers = idos.reduce((accumulator, ido ) => {
    return accumulator + parseInt(ido.participationInfo.totalCountUserParticipated);
  }, 0);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Grid container direction="row" spacing={isSmallScreen?0:2}>
     
      <Grid item xs={12}>
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          divider={<Divider orientation="horizontal" flexItem />}
        >
           <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={1}
                    >
          <img alt="Site" src={
                           API.imageUrl +
                           API.staticSites  +
                           encodeURIComponent(selectedSite.name.replace(/\s/g, ""))
                          
                        } width={"30px"} height={"30px"}></img>
          <Typography variant="h4">{selectedSite.name}</Typography>
          </Stack>
          

          <Typography>Year Of Foundation:{selectedSite.siteInfo.year}</Typography>
         
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={2}
          >
            <Typography>
              Native Token
              <Paper sx={{ color: theme.palette.subColor.dark }} px={"8px"}>{selectedSite.name}</Paper>{" "}
            </Typography>
            <Typography>
              Price{" "}
              <Paper sx={{ color: theme.palette.subColor.dark }}>
                <Price tokenAddress={selectedSite.mainToken[0].address} tokenNetwork={selectedSite.mainToken[0].network}></Price>
                </Paper>
            </Typography>
          </Stack>
          <Stack
          direction="row"
          justifyContent="left"
          spacing={1}
          alignItems="left"
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Stack direction="column" alignItems="left">
            <Typography>Numbers of IDOs</Typography>
            <Typography variant="h6" sx={{ color: theme.palette.subColor.dark }}>
              {idos.length}
            </Typography>
          </Stack>

          <Stack direction="column" alignItems="right">
            <Typography>Total User</Typography>
            <Typography variant="h6" sx={{ color: theme.palette.subColor.dark }}>
              {sumUsers}
            </Typography>
          </Stack>
        </Stack>
          <Box>
          <InfoLinks max={3} info={[
            { tooltip: "website", Icon: <LanguageIcon onClick={() => window.open(selectedSite.siteInfo.website, "_blank")}></LanguageIcon> },
            { tooltip: "twitter", Icon: <TwitterIcon onClick={() => window.open(selectedSite.siteInfo.twitter, "_blank")}></TwitterIcon> },
            { tooltip: "telegram", Icon: <TelegramIcon onClick={() => window.open(selectedSite.siteInfo.telegram, "_blank")}></TelegramIcon> },
         
            
            ]}/>
          
          
     
          </Box>
          {seeMore ? <Link to={"/websites/" + selectedSite.name} style={{color:theme.palette.routerLink.color}}>More Info</Link> : <React.Fragment></React.Fragment>}
        </Stack>
        
      </Grid>
      
      
    </Grid>
    
  );
};
