import React from 'react';
import { createContext, useState, useContext, useMemo } from 'react';
import { MENU_LIST } from '../constant/routes';







const DrawerContext = createContext(undefined);

export const DrawerContextProvider = ({ children }) => {
    const [isOpened, toggleIsOpened] = useState(true);

    const value = useMemo(() => ({
            isOpened,
            toggleIsOpened,
             MENU_LIST
        })
        , [isOpened]);

    return <DrawerContext.Provider value={value}>
            {children}
        </DrawerContext.Provider>;
};

export const useDrawerContext = () => {
    const context = useContext(DrawerContext);
    if (context === undefined) {
        throw new Error(
            "useDrawerContext must be used within a DrawerContextProvider"
        );
    }
    return context;
};
