import { idoContract } from "../../../constant/abi";
import moment from "moment";
import { getChainString } from "../../../utils/solidtyInterect";
import {
  setGlobalLoading,
  setGlobalLoadingSubMessage,
} from "../../../stateManagement/slices/ui/uitls";

const claimStatus = {
  0: "PENDING",
  1: "OPEN",
  2: "CLOSED",
};
const eventCase = {
  clsoed:"CLOSED",
  notJoining: "NOTJOIN"
}
const getInfoAllocations = async function(contractAddress, address, library) {
  try {
    const contract = new library.eth.Contract(idoContract, contractAddress);
    const contractInfoAllocation = await contract.methods
      .infoAllocations()
      .call({ from: address });

    let a = _calculateAllowInfo(contractInfoAllocation);
    a = a.infoAllocation.filter((ido) => ido.allocationAmount !== "0");
    return a;
  } catch (e) {
    console.log("error", e);
    throw e ;
  }
};
const _calculateAllowInfo = (contractInfoAllocation) => {
  let infoAllocation = [];

  const row1 = contractInfoAllocation[0];
  const row2 = contractInfoAllocation[1];
  const row3 = contractInfoAllocation[2];
  const row4 = contractInfoAllocation[3];
  const row5 = contractInfoAllocation[4];

  if (row1.length >= 2) {
    if (row1[0] ^ (row1[1] === 0)) {
      //layout2
      for (let i = 0; i < row1.length - 1; i++) {
        const item = row1[i];
        const nextItem = row1[i + 1];
        if (item === nextItem) {
          moment(row3[i] * 1000 || 0)
            .utc()
            .format("YYYY-MM-DD HH:mm:ss");

          const allocationAmount =
            row2[i] === row2[i + 1] ? row2[i] : `${row2[i]}-${row2[i + 1]}`;

          const timestamp =
            row3[i] === row3[i + 1]
              ? parseInt(row3[i]) === 0
                ? "DEX Listing"
                : moment(row3[i] * 1000 || 0)
                    .utc()
                    .format("YYYY-MM-DD HH:mm:ss")
              : `${moment(row3[i] * 1000 || 0)
                  .utc()
                  .format("YYYY-MM-DD HH:mm:ss")}-${moment(
                  row3[i + 1] * 1000 || 0
                )
                  .utc()
                  .format("YYYY-MM-DD HH:mm:ss")}`;
          const percentage = row4[i + 1];

          infoAllocation.push({
            no: item,
            allocationAmount,
            timestamp,
            claimedAmount: row4[i],
            status: claimStatus[row5[i]],
            percentage,
          });
        }
      }

      return {
        layout: 2,
        infoAllocation,
      };
    }
  }

  for (let i = 0; i < row1.length; i++) {
    infoAllocation.push({
      no: row1[i],
      allocationAmount: row2[i],
      timestamp:
        parseInt(row3[i]) === 0
          ? "DEX Listing"
          : moment(parseInt(row3[i]) * 1000 || 0)
              .utc()
              .format("YYYY-MM-DD HH:mm:ss"),
      claimedAmount: row4[i],
      status: claimStatus[row5[i]],
    });
  }

  return {
    layout: 1,
    infoAllocation,
  };
};

function closeEventsKey(account,chainId){
  return account + eventCase.clsoed + chainId   ;
}
function notJoinedEventsKey(account,chainId){
  return account + eventCase.notJoining + chainId  ;
}

const closedEvents =  function(account,chainId) {
  let retVal =    JSON.parse(localStorage.getItem(closeEventsKey(account,chainId)));
 return retVal ? retVal : [] ;
}
const saveClosedEvents =  function(account,saveData,chainId) {
  //localStorage.removeItem(closeEventsKey(account,chainId));
  localStorage.setItem(closeEventsKey(account,chainId), JSON.stringify(saveData));
}

const notJoinedEvents =  function(account,chainId) {
  let retVal =  JSON.parse(localStorage.getItem(notJoinedEventsKey(account,chainId)));
  return retVal ? retVal : [] ;
}
const saveNotJoinedEvents =  function(account,saveData,chainId) {
  //localStorage.removeItem(notJoinedEventsKey(account,chainId));
  localStorage.setItem(notJoinedEventsKey(account,chainId), JSON.stringify(saveData));
}
;

export const eventsAll = async function({ site,  ido,  idos = [],  account = "",  chainId,  library,  setEvents,  dispatch,}) {
  dispatch( setGlobalLoading({value: true,message:"Looking for Your IDOs at Chain: " +getChainString[chainId] +" and Site: " + site, }));
  let closedEventsList = closedEvents(account,chainId) ;
  let notJoinedEventsList = notJoinedEvents(account,chainId) ;
  let closedEventListLoop  =
  closedEventsList.filter(r=>r.site === site || site==="ALL").filter((r) => r.ido === ido ||  ido === "ALL");
  let notJoinedEventsListLoop  = 
  notJoinedEventsList.filter(r=>r.site === site || site==="ALL").filter((r) => r.ido === ido ||  ido === "ALL");
  let retValues = [];
  let foundedIdo = closedEventListLoop.length   ;
 
  if (!account) {
    dispatch(setGlobalLoading({ value: false }));
    return undefined;
  }


  const selectedIdosBySite = idos.filter(
    (idoSite) => idoSite.name === site || site === "ALL"
  );

  for (let i = 0; i < selectedIdosBySite.length; i++) {
    let s = selectedIdosBySite[i];
    let selectedIdos = s.idos.filter((idoF) => {
      return (
        idoF.network === getChainString[chainId] &&
        (idoF.name.includes(ido) || ido === "ALL")
      );
    });
    for (let j = 0; j < selectedIdos.length; j++) {
      let e = selectedIdos[j];
      if ( !(closedEventListLoop.some((r) => r.site === s.name && e.idoContract === r.idoContract) ||
      notJoinedEventsListLoop.some((r) => r.site === s.name && e.idoContract === r.idoContract))) 
      {
        try{
          let infoAllocation = await getInfoAllocations(e.idoContract,account,library);
          const data = {site: s.name,ido: e.name,idoName: e.info.name,idoContract: e.idoContract,telegram: e.info.telegram,
            website: e.info.website,twitter: e.info.twitter,idoLogo: e.info.logo2,address: { tokenAddress: e.address, network: e.network },
            infoAllocation: infoAllocation,participationInfo: e.participationInfo,rules: e.rules,
          };
          if (infoAllocation && infoAllocation.length > 0) {
            foundedIdo +=1 ;
            if (infoAllocation[infoAllocation.length - 1].status === claimStatus[2]) {
              
              closedEventsList.push(data);
              closedEventListLoop.push(data);
            }else {
  
              retValues.push(data);
            }
          } else {
            notJoinedEventsList.push(data);
          }
          dispatch(setGlobalLoadingSubMessage({subMessage: "Funded/Scanned:" +foundedIdo +" / " +(retValues.length+closedEventListLoop.length+notJoinedEventsList.length) +" IDOS",}));


        }catch(e){
         


        }         
      } else {
        const closedEvent = closedEventListLoop.find(
          (r) => r.site === s.name && e.idoContract === r.idoContract
        ) ;
        if(closedEvent){
          retValues.push(closedEvent);
        }
       
      }
    }
  }

  saveClosedEvents( account,closedEventsList,chainId);
  saveNotJoinedEvents( account,notJoinedEventsList,chainId);
  setEvents([...retValues]);

  dispatch(setGlobalLoading({ value: false }));
};
