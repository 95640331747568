import React from "react";
import {  TableCell, TableHead, TableRow,  Typography } from "@mui/material";
import { useStylesStickColumnTable } from "../css/Utility";

export function TableHeadSticky({ headList , preRow,postRow }) {
  const classes = useStylesStickColumnTable();
  return (

        <TableHead>
          {preRow}
          <TableRow>
            {headList.map((head, i) => {
                return <TableCell align="left" sx={{fontWeight:"bold" , padding:"8px"}} className={i===0?classes.sticky:""} title={head.exp} >
                   <Typography variant="subtitle1">{head.title}</Typography>
                 
                  </TableCell>;
              })}
          </TableRow>
         
        </TableHead>

      
 
  );
}
